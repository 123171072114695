const REVALIDATE_DELAY = 1000;
const SUB_ARTICLES = Symbol();
const VALIDATION_PREFIX_ADD = "add_";
const VALIDATION_PREFIX_REMOVE = "remove_";
const VALIDATION_ERRRORS = "errors";
const VALIDATION_WARNINGS = "warnings";
const VALIDATION_SYMBOL = Symbol("MValidation");
const IGNORE_MESSAGE_SYMBOL = Symbol();
export const REVALIDATE_SYMBOL = Symbol("MRevalidate");
export const VALIDATION_REASONS = { add: "Add", update: "Update", delete: "Delete", save: "Save" };

class FieldsValidations {
	constructor(aInstance, aField) {
		this.instance = new WeakRef(aInstance);
		this.fields = { [aField.name]: aField };
		this.revalidate = () => {
			const instance = this.instance.deref();
			if (!instance) return;
			delete instance[REVALIDATE_SYMBOL];
			instance.revalidate(this.fields);
		};
		this.timerID = setTimeout(this.revalidate, REVALIDATE_DELAY);
	}

	addField(aField) {
		clearTimeout(this.timerID);
		this.fields[aField.name] = aField;
		this.timerID = setTimeout(this.revalidate, REVALIDATE_DELAY);
		return true;
	}
}

class VMessage {
	//#region Constructor
	constructor(aType, aMessage, aFormatter, aFields) {
		this.message = aMessage;
		this.formatter = aFormatter;
		if (aFields) this.fields = aFields;
		if (aType === VALIDATION_WARNINGS) {
			this.ignore = () => (this[IGNORE_MESSAGE_SYMBOL] = true);
		}
	}
	asPath(aPrefixFormatter) {
		const orginal = this;
		const result = new VMessage();
		Object.defineProperty(result, IGNORE_MESSAGE_SYMBOL, {
			get() {
				return orginal[IGNORE_MESSAGE_SYMBOL];
			},
			set(aValue) {
				orginal[IGNORE_MESSAGE_SYMBOL] = aValue;
			},
		});
		result.asString = (...args) => {
			return aPrefixFormatter(...args) + "." + orginal.asString(...args);
		};
		if (orginal.ignore) result.ignore = orginal.ignore;
		return result;
	}
	//#endregion Constructor
	//#region Formatting
	asString(aTrans) {
		return this.formatter(aTrans);
	}
	//#endregion Formatting
}

class VArticles {
	//#region Constructor
	constructor(...args) {
		if (args.length > 0) this.add(...args);
	}
	//#endregion Constructor
	//#region Articles
	#count = 0;
	get isEmpty() {
		return this.#count === 0;
	}
	add(aName, aMessage) {
		if (!aName || (typeof aName !== "string" && !(aName instanceof String))) {
			throw new Error(`messageID, expected type string but get ${aName}!..`);
		}
		if (!aMessage || !(aMessage instanceof VMessage)) {
			throw new Error(`message, expected type object but get ${aMessage}!..`);
		}
		this.#count++;
		this[aName] = aMessage;
	}
	#expanded;
	#addSubArticles() {
		if (!this[SUB_ARTICLES] || this.#expanded === true) return;
		this.#expanded = true;
		for (let subArticles of this[SUB_ARTICLES]) {
			subArticles.articles.#addSubArticles();
			const prefix = subArticles.prefix;
			const prefixFormatter = subArticles.formatter;
			for (let [key, message] of Object.entries(subArticles.articles)) {
				this[`${prefix}.${key}`] = message.asPath(prefixFormatter);
				this.#count++;
			}
		}
	}
	messages(aAll) {
		this.#addSubArticles(aAll);
		const result = [];
		for (let message of Object.values(this)) {
			if (aAll || !message[IGNORE_MESSAGE_SYMBOL]) {
				result.push(message);
			}
		}
		return result;
	}
	messagesArray(aTrans, aAll) {
		this.#addSubArticles(aAll);
		let result = [];
		for (let message of Object.values(this)) {
			if (aAll || !message[IGNORE_MESSAGE_SYMBOL]) {
				result.push(message.asString(aTrans));
			}
		}
		return result;
	}
	allMessages(aTrans, aAll) {
		this.#addSubArticles(aAll);
		let result = "";
		for (let message of Object.values(this)) {
			if (aAll || !message[IGNORE_MESSAGE_SYMBOL]) {
				if (result) result += "\n";
				result += message.asString(aTrans);
			}
		}
		return result;
	}
	messagesKeys(aAll) {
		this.#addSubArticles(aAll);
		let result = "";
		for (let [key, message] of Object.entries(this)) {
			if (aAll || !message[IGNORE_MESSAGE_SYMBOL]) {
				if (result) result += "\n";
				result += key;
			}
		}
		return result === "" ? undefined : result;
	}
	forEach(aCallback) {
		this.#addSubArticles();
		for (let [key, message] of Object.entries(this)) {
			aCallback(key, message);
		}
	}
	//#endregion Articles
}

class VFieldArticles extends VArticles {
	//#region Constructor
	constructor(aMessage) {
		super();
		if (aMessage) this.add(aMessage);
	}
	//#endregion Constructor
	//#region Articles
	add(aMessage) {
		if (!aMessage || !(aMessage instanceof VMessage)) {
			throw new Error(`message, expected type object but get ${aMessage}!..`);
		}
		return super.add(aMessage.message, aMessage);
	}
	//#endregion Articles
}

export class Validator {
	//#region Constructor
	#reason;
	#configs;
	#instance;
	constructor(aInstance, aReason, aFields) {
		this.#reason = aReason;
		this.#instance = aInstance;
		this.#configs = aInstance.meta.configs;
		if (aFields) this.fields = aFields;
	}
	//#endregion Constructor
	//#region Reason
	get reason() {
		return this.#reason;
	}
	get isForAdding() {
		return this.#reason === VALIDATION_REASONS.add;
	}
	get isForUpdating() {
		return this.#reason === VALIDATION_REASONS.update;
	}
	get isForDeleting() {
		return this.#reason === VALIDATION_REASONS.delete;
	}
	get isForSaving() {
		return this.#reason === VALIDATION_REASONS.save;
	}
	//#endregion Reason
	//#region isValidatable
	isValidatable(aName) {
		const fields = this.fields;
		if (!fields) return true;
		if (Array.isArray(aName)) {
			return !!aName.find((name) => fields[name]);
		}
		return !!fields[aName];
	}
	isAnyValidatable(aName) {
		return this.isValidatable(aName);
	}
	isAllValidatable(aName) {
		const fields = this.fields;
		if (!fields) return true;
		if (Array.isArray(aName)) {
			return aName.every((name) => fields[name]);
		}
		return !!fields[aName];
	}
	//#endregion isValidatable
	//#region Messages
	#defaultMessageFormatter(aFields, aMessage) {
		const configs = this.#configs;
		return (aTrans) => {
			let result = configs.translate(aTrans, aMessage);
			if (aFields) {
				let fields;
				const loc_AddField = (name) => {
					const field = configs.fields?.[name];
					const title = field ? configs.translate(aTrans, field.title || "fields." + name) : name;
					fields = fields ? fields + "," + title : title;
				};
				if (!Array.isArray(aFields)) loc_AddField(aFields);
				else aFields.forEach(loc_AddField);
				const pattern = "%s";
				fields = `(${fields})`;
				if (!result.includes(pattern)) result += fields;
				else result = result.replace(pattern, fields);
			}
			return result;
		};
	}
	#treatNotify(aAdding, aType, aFields, aMessage, aFormatter) {
		const type = (aAdding ? VALIDATION_PREFIX_ADD : VALIDATION_PREFIX_REMOVE) + aType || VALIDATION_ERRRORS;
		const fields = Array.isArray(aFields) ? aFields.toString() : aFields;
		const name = fields ? `${aMessage}(${fields})` : aMessage;
		let formatter;
		if (aAdding) {
			formatter = typeof aFormatter === "function" ? aFormatter : this.#defaultMessageFormatter(aFields, aMessage);
		}
		let all = this[type];
		const message = new VMessage(aType, aMessage, formatter, aFields);
		if (all) all.add(name, message);
		else {
			const types = new VArticles(name, message);
			this[type] = types;
		}
	}
	error(aAdding, aFields, aMessage, aFormatter) {
		return this.#treatNotify(aAdding, VALIDATION_ERRRORS, aFields, aMessage, aFormatter);
	}
	addError(aFields, aMessage, aFormatter) {
		return this.#treatNotify(true, VALIDATION_ERRRORS, aFields, aMessage, aFormatter);
	}
	removeError(aFields, aMessage, aFormatter) {
		return this.#treatNotify(false, VALIDATION_ERRRORS, aFields, aMessage, aFormatter);
	}
	warning(aAdding, aFields, aMessage, aFormatter) {
		return this.#treatNotify(aAdding, VALIDATION_WARNINGS, aFields, aMessage, aFormatter);
	}
	addWarning(aFields, aMessage, aFormatter) {
		return this.#treatNotify(true, VALIDATION_WARNINGS, aFields, aMessage, aFormatter);
	}
	removeWarning(aFields, aMessage, aFormatter) {
		return this.#treatNotify(false, VALIDATION_WARNINGS, aFields, aMessage, aFormatter);
	}
	//#endregion Messages
	//#region SubArticles
	addArticles(aPrefix, aPrefixFormatter, aArticles) {
		if (!aArticles) return;
		const { [VALIDATION_ERRRORS]: errors, [VALIDATION_WARNINGS]: warnings } = aArticles;
		if (!errors && !warnings) return;
		let subArticles = this[SUB_ARTICLES];
		if (!subArticles) {
			subArticles = { [VALIDATION_ERRRORS]: [], [VALIDATION_WARNINGS]: [] };
			this[SUB_ARTICLES] = subArticles;
		}
		if (errors && !errors.isEmpty) {
			subArticles[VALIDATION_ERRRORS].push({ prefix: aPrefix, formatter: aPrefixFormatter, articles: errors });
		}
		if (warnings && !warnings.isEmpty) {
			subArticles[VALIDATION_WARNINGS].push({ prefix: aPrefix, formatter: aPrefixFormatter, articles: warnings });
		}
	}
	//#endregion SubArticles
	//#region Approve
	approveAt(aInstance) {
		if (this.#instance !== aInstance) {
			throw new Error("Unexpected validation instance!...");
		}
		const messages = {};
		const fieldsArticles = {};
		const loc_fieldArticle = (aType, aIsChanged, aMessage, aField) => {
			let fieldArticles = fieldsArticles[aField];
			if (!fieldArticles) {
				let articles = new VFieldArticles(aMessage);
				articles.isChanged = aIsChanged;
				fieldArticles = { [aType]: articles };
				fieldsArticles[aField] = fieldArticles;
			} else {
				let articles = fieldArticles[aType];
				if (articles) {
					articles.isChanged = articles.isChanged || aIsChanged;
					if (aMessage) articles.add(aMessage);
				} else {
					let articles = new VFieldArticles(aMessage);
					articles.isChanged = aIsChanged;
					fieldArticles[aType] = articles;
				}
			}
		};
		const loop_messages = (aType, aMessages, aIsNew, aOldMessages, aRemovedMessages) => {
			let all_messages = messages[aType];
			if (!all_messages && aIsNew) {
				all_messages = new VArticles();
				messages[aType] = all_messages;
			}
			for (const [key, message] of Object.entries(aMessages)) {
				if (all_messages?.[key]) continue;
				const fields = message.fields;
				const keep = aIsNew || (!aRemovedMessages?.[key] && (!fields || !this.isAnyValidatable(fields)));
				if (!keep) {
					if (!Array.isArray(fields)) loc_fieldArticle(aType, true, undefined, fields);
					else fields.forEach(loc_fieldArticle.bind(undefined, aType, true, undefined));
					continue;
				}
				if (!all_messages) {
					all_messages = new VArticles();
					messages[aType] = all_messages;
				}
				if (aOldMessages?.[key]?.[IGNORE_MESSAGE_SYMBOL]) {
					message[IGNORE_MESSAGE_SYMBOL] = true;
				}
				all_messages.add(key, message);
				if (fields) {
					const isChanged = aIsNew && aOldMessages?.[key] === undefined;
					if (!Array.isArray(fields)) loc_fieldArticle(aType, isChanged, message, fields);
					else fields.forEach(loc_fieldArticle.bind(undefined, aType, isChanged, message));
				}
			}
		};
		const subArticles = this[SUB_ARTICLES];
		if (subArticles) {
			let list = subArticles[VALIDATION_ERRRORS];
			if (list?.length > 0) {
				const articles = new VArticles();
				messages[VALIDATION_ERRRORS] = articles;
				articles[SUB_ARTICLES] = list;
			}
			list = subArticles[VALIDATION_WARNINGS];
			if (list?.length > 0) {
				const articles = new VArticles();
				messages[VALIDATION_WARNINGS] = articles;
				articles[SUB_ARTICLES] = list;
			}
		}
		const old = aInstance[VALIDATION_SYMBOL];
		const new_errors = this[VALIDATION_PREFIX_ADD + VALIDATION_ERRRORS];
		const new_warnings = this[VALIDATION_PREFIX_ADD + VALIDATION_WARNINGS];
		const removed_errors = this[VALIDATION_PREFIX_REMOVE + VALIDATION_ERRRORS];
		const removed_warnings = this[VALIDATION_PREFIX_REMOVE + VALIDATION_WARNINGS];
		const old_errors = old?.[VALIDATION_ERRRORS];
		const old_warnings = old?.[VALIDATION_WARNINGS];
		if (new_errors) loop_messages(VALIDATION_ERRRORS, new_errors, true, old_errors);
		if (new_warnings) loop_messages(VALIDATION_WARNINGS, new_warnings, true, old_warnings);
		if (old_errors) loop_messages(VALIDATION_ERRRORS, old_errors, false, undefined, removed_errors);
		if (old_warnings) loop_messages(VALIDATION_WARNINGS, old_warnings, false, undefined, removed_warnings);
		const hasMessages = Object.keys(messages).length > 0;
		if (hasMessages) aInstance[VALIDATION_SYMBOL] = messages;
		else delete aInstance[VALIDATION_SYMBOL];
		for (let [name, articles] of Object.entries(fieldsArticles)) {
			const errors = articles[VALIDATION_ERRRORS];
			const warnings = articles[VALIDATION_WARNINGS];
			const isChanged = errors?.isChanged || warnings?.isChanged;
			if (isChanged) {
				const status = {};
				if (errors?.isChanged) {
					if (errors.isEmpty) status[VALIDATION_ERRRORS] = undefined;
					else {
						delete errors.isChanged;
						status[VALIDATION_ERRRORS] = errors;
					}
				}
				if (warnings?.isChanged) {
					if (warnings.isEmpty) status[VALIDATION_WARNINGS] = undefined;
					else {
						delete warnings.isChanged;
						status[VALIDATION_WARNINGS] = warnings;
					}
				}
				const field = aInstance[name];
				const func = field?.includeStatus;
				if (typeof func === "function") func.call(field, status);
				else console.error(`field '${name}' don't have status!..`);
			}
		}
		if (hasMessages) {
			messages.validationCheck = checkValidationResult;
			return messages;
		}
	}
	//#endregion Approve
}

function checkValidationResult(aIgnoreAllWarnings, aDontThrowError) {
	const { [VALIDATION_ERRRORS]: errors, [VALIDATION_WARNINGS]: warnings } = this;
	let result = errors?.messagesKeys();
	if (result) {
		if (aDontThrowError === true) return false;
		const wRes = aIgnoreAllWarnings !== true && warnings?.messagesKeys();
		if (wRes) {
			result += "\n" + wRes;
		}
	} else result = aIgnoreAllWarnings !== true && warnings?.messagesKeys();
	if (result) {
		if (aDontThrowError === true) return false;
		throw new Error(`\nValidation Errors:\n${result}`);
	}
	return true;
}

export function revalidateField(aInstance, aField) {
	if (!aInstance[REVALIDATE_SYMBOL]?.addField(aField)) {
		aInstance[REVALIDATE_SYMBOL] = new FieldsValidations(aInstance, aField);
	}
}

export class VAcceptedField {
	//#region Constructor
	#fields;
	constructor(aFields) {
		this.#fields = aFields;
	}
	//#endregion Constructor;
	//#region Information
	get [Symbol.for("MManagementObject")]() {
		return true;
	}
	//#endregion Information
	//#region ApiMethods
	toApiRequest(aRequest) {
		const instance = this.#fields.instance;
		if (!instance || instance !== aRequest.startAt) return;
		const warnings = instance?.[VALIDATION_SYMBOL]?.[VALIDATION_WARNINGS];
		if (!warnings) return;
		const info = aRequest.blockStart([]);
		try {
			let index = 0;
			warnings.forEach((path) => aRequest.append(index++, path.toString()));
		} finally {
			aRequest.blockFinished(info);
		}
	}
	fromApiRequest(aValue, aRequestInfo) {
		this.#accepted = aValue;
	}
	fromApiResponse() {}
	//#endregion ApiMethods
	//#region applyAccepted
	#accepted;
	applyAccepted() {
		if (!this.#accepted) return;
		const instance = this.#fields.instance;
		if (!instance) return;
		const warnings = instance[VALIDATION_SYMBOL]?.[VALIDATION_WARNINGS];
		if (!warnings) return;
		this.#accepted.forEach((warning) => {
			warnings[warning]?.ignore();
		});
	}
	//#endregion ignore
}
