export { MApplication, MApplication as Application } from "./UIApplication.js";
export const controllersUtils = MApplication.utils;
export * from "./UIAPI.js";
import UIApp from "./UIApp.js";
import MAppExtender from "../../shared/core/MApp.js";
export const MApp = MAppExtender(UIApp);
import UIController from "./UIController.js";
import MControllerExtender from "../../shared/core/MController.js";
export const MController = MControllerExtender(UIController);
export * from "../../shared/core/management/MSetMngmt.js";
export * from "./UIMeta.js";
export * from "./UIControllersList.js";
export default MApplication;
