//#region Constants
const VALUE_TYPE_ARRAY = "array";
const VALUE_TYPE_OBJECT = "object";
const VALUE_TYPE_STRING = "string";
const VALUE_TYPE_NUMBER = "number";
const VALUE_TYPE_BOOLEAN = "boolean";
const TOKEN_TYPE_ARRAY = VALUE_TYPE_ARRAY;
const TOKEN_TYPE_STRING = VALUE_TYPE_STRING;
const TOKEN_TYPE_LETTERS = "letters";
const TOKEN_TYPE_FIELD = "field";
const TOKEN_TYPE_OPERATOR = "operator";
const TOKEN_TYPE_PREDICATE = "predicate";
const COMBINE_TYPE_OR = "or";
const COMBINE_TYPE_AND = "and";
const EXPRE_TYPE_FIELD = TOKEN_TYPE_FIELD;
const EXPRE_TYPE_VALUE = "value";
const EXPRE_TYPE_PREDICATE = TOKEN_TYPE_PREDICATE;
const EXPRE_KIND_COMBINE = "combine";
const EXPRE_KIND_REDICATE = EXPRE_TYPE_PREDICATE;
const EXPRE_KIND_FIELD = EXPRE_TYPE_FIELD;
const EXPRE_KIND_VALUE = EXPRE_TYPE_VALUE;

const GET_ACCEPTED_EXPRES = Symbol();
const SYMBOL_PARSERS = Symbol();
const SYMBOL_ALL_PREDICATES = Symbol();
const SYMBOL_NAMED_PREDICATES = Symbol();
const SYMBOL_VALUE_EXPRESSION = Symbol();
const SYMBOL_FIELD_EXPRESSION = Symbol();
//#endregion Constants
//#region StringParsing
function skipSpaces(aStart, aString, aSpace = " ") {
	let i = aStart;
	const length = aString.length;
	while (i < length && aString[i] === aSpace) i++;
	return i;
}

function operatorSymbol(aString, aPos) {
	const char = aString[aPos];
	switch (char) {
		case "=":
			return "=";
		case ">": {
			const next = aString[aPos + 1];
			if (next === "=") return ">=";
			if (next === "<") return "><"; //notBetween
			else return ">";
		}
		case "<": {
			const next = aString[aPos + 1];
			if (next === ">") return "!="; //notEqual
			else if (next === "=") {
				if (aString[aPos + 2] === ">") return "<=>"; //between
				else return "<=";
			} else return "<";
		}
		case "!":
			if (aString[aPos + 1] === "=") return "!=";
			else break;
	}
}

function createToken(aValue, aType, aStart, aEnd, aPrefix, aSuffix, aRepeat) {
	let _full;
	const result = {
		token: aValue,
		type: aType,
		start: aStart,
		end: aEnd,
		get fullToken() {
			if (!_full) {
				const repeat = aRepeat || 1;
				_full = this.token;
				for (let i = 0; i < repeat; i++) {
					if (aPrefix) _full = aPrefix + _full;
					if (aSuffix) _full = _full + aSuffix;
				}
			}
			return _full;
		},
	};
	return result;
}

function parseToken(aStart, aString) {
	let i = skipSpaces(aStart, aString);
	const length = aString.length;
	if (i === length) {
		throw new Error("command ended, can not parse token!..");
	}
	const start = i;
	let char = aString[i];
	let count, incChar, decChar, type;
	let perfix, suffix;
	switch (char) {
		case "`":
			count = 1;
			perfix = suffix = decChar = "`";
			type = TOKEN_TYPE_FIELD;
			i++;
			break;
		case "'":
			decChar = "'";
		case '"':
			decChar = decChar || '"';
			perfix = suffix = decChar;
			count = 0;
			while (i < length && aString[i] === decChar) {
				count++;
				i++;
			}
			type = TOKEN_TYPE_STRING;
			break;
		case "(":
			perfix = incChar = "(";
			suffix = decChar = ")";
			count = 1;
			type = TOKEN_TYPE_PREDICATE;
			i++;
			break;
		case "[":
			perfix = incChar = "[";
			suffix = decChar = "]";
			count = 1;
			type = TOKEN_TYPE_ARRAY;
			i++;
			break;
		default: {
			const operator = operatorSymbol(aString, i);
			if (operator) {
				return createToken(operator, TOKEN_TYPE_OPERATOR, start, i + operator.length);
			}
			type = TOKEN_TYPE_LETTERS;
		}
	}
	const initCount = count ?? 0;
	for (; i < length; i++) {
		char = aString[i];
		if (!char) break;
		if (char === incChar) count++;
		else if (char === decChar && --count === 0) {
			i++;
			break;
		} else if (count === undefined) {
			if (char === " ") break;
			else {
				const operator = operatorSymbol(aString, i);
				if (operator) break;
			}
		}
	}
	if (count) {
		const str = aString.substring(0, start) + "!!!" + aString.substring(start);
		throw new Error(`unexpected token end! ${str}..`);
	}
	const skip = initCount ?? 0;
	const str = aString.substring(start + skip, i - skip);
	return createToken(str, type, start, i, perfix, suffix, initCount);
}

function scanPredicate(aEngine, aStart, aString) {
	const length = aString.length;
	let start = skipSpaces(aStart, aString);
	if (start === length) {
		throw new Error("command ended, can not scan predicate!..");
	}
	const left = parseToken(start, aString);
	if (left.type === TOKEN_TYPE_PREDICATE) {
		const predicate = parsePredicate(aEngine, left.token);
		return { predicate, end: left.end };
	}
	const operator = parseToken(left.end, aString);
	const cls = aEngine[SYMBOL_ALL_PREDICATES][operator.token];
	if (!cls) {
		throw new Error(`operator ${operator?.token || operator} was not treated!..`);
	}
	const result = cls.parse_cls(aEngine, left, operator.end, aString);
	if (!result) {
		throw new Error(`command ${aString} don't have predicate!..`);
	}
	return result;
}

function parsePredicate(aEngine, aString) {
	let i = 0;
	let result;
	const length = aString.length;
	while (i < length) {
		i = skipSpaces(i, aString);
		if (i === length) break;
		if (result) {
			const operator = parseToken(i, aString);
			const combine = operator && aEngine[SYMBOL_ALL_PREDICATES][operator.token];
			if (!(combine?.prototype instanceof MFPredicateCombine)) {
				throw new Error(`can not get combine mode for command ${aString}!..`);
			}
			const { predicate: right, end } = scanPredicate(aEngine, operator.end, aString);
			result = new combine(result, right);
			i = end;
		} else {
			var { predicate, end } = scanPredicate(aEngine, i, aString);
			result = predicate;
			i = end;
		}
	}
	if (!result) {
		throw new Error(`command ${aString} don't have predicate!..`);
	}
	return result;
}

function expressionOf(aEngine, aToken, aAccepted, aExplication) {
	const acceptPredicate = () => {
		if (!aAccepted[EXPRE_TYPE_PREDICATE]) {
			throw new Error(`predicate ${aExplication} dont accept predicate!..`);
		}
	};
	let value;
	let type = aToken.type;
	switch (type) {
		case TOKEN_TYPE_FIELD:
			return aEngine[SYMBOL_FIELD_EXPRESSION].create(aEngine, aToken.token, aAccepted, aExplication);
		case TOKEN_TYPE_LETTERS:
			value = aEngine[SYMBOL_FIELD_EXPRESSION].tryCreate(aEngine, aToken.token, aAccepted);
			if (value) return value;
		case TOKEN_TYPE_ARRAY:
		case TOKEN_TYPE_STRING:
			value = aEngine[SYMBOL_VALUE_EXPRESSION].tryCreate(aToken.token, type, aAccepted);
			if (value) return value;
			throw new Error(`predicate ${aExplication} dont accept value type: ${type}!..`);
		case TOKEN_TYPE_OPERATOR:
			throw new Error(`can not create expression by operator for ${aExplication}!..`);
		case TOKEN_TYPE_PREDICATE:
			acceptPredicate();
			return parsePredicate(aEngine, aToken.token);
		default:
			if (type instanceof MFExpressionBase) {
				acceptPredicate();
				return type;
			}
			throw new Error(`unkown token type:'${type}' for ${aExplication}!..`);
	}
}
//#endregion StringParsing
//#region ExpressionsClasses
class MFExpressionBase {
	//#region Constructor
	constructor(...args) {
		if (args) {
			for (let arg of args) {
				if (!(arg instanceof MFExpressionBase)) {
					throw new Error(`args for ${this.exprType} must be expression!.`);
				}
			}
		}
	}
	static parse_cls(aEngine, aLeft, aRightStart, aString) {
		throw new Error(`method parse_cls must get interupted in ${this.exprType}!..`);
	}
	//#endregion Constructor
	//#region Information
	get kind() {
		return this.constructor.kind;
	}
	get exprType() {
		return this.constructor.exprType;
	}
	static get exprType() {
		return this.name;
	}
	static get kind() {
		throw new Error(`method kind must get interupted in ${this.exprType}!..`);
	}
	//#endregion Information
	//#region Management
	asValue(aInstance) {
		throw new Error(`method asValue must get interupted in ${this.exprType}!..`);
	}
	asString() {
		throw new Error(`method asString must get interupted in ${this.exprType}!..`);
	}
	//#endregion Management
	//#region Apply
	test(aInstance) {
		return !!this.asValue(aInstance);
	}
	//#endregion Apply
}

class MFExpressionField extends MFExpressionBase {
	//#region Constructor
	#fieldName;
	constructor(...args) {
		const field = args[0];
		if (args.length !== 1 || !field || (typeof field !== "string" && !(field instanceof String))) {
			throw new Error("fieldExpression accept only 1 param with type string!.");
		}
		super();
		this.#fieldName = field;
	}
	static create(aEngine, aValue, aAccepted, aExplication) {
		if (!aAccepted[EXPRE_TYPE_FIELD]) {
			throw new Error(`predicate ${aExplication} dont accept fields!..`);
		}
		const field = aEngine.fieldGetter(aValue);
		if (!field) {
			throw new Error(`predicate ${aExplication} fieldName ${aValue} dos not exists!..`);
		}
		if (typeof field === "function") return field(this, aValue, aEngine);
		else return new this(field);
	}
	static tryCreate(aEngine, aValue, aAccepted) {
		const accepted =
			aValue &&
			aAccepted[EXPRE_TYPE_FIELD] &&
			(typeof aValue === "string" || aValue instanceof String) &&
			aValue.length > 0 &&
			!Number.parseFloat(aValue);
		if (!accepted) return false;
		let name = aValue;
		if (aValue[0] === "`" && aValue[aValue.length - 1] === "`") {
			name = aValue.substring(1, aValue.length - 1);
		}
		const field = aEngine.fieldGetter(name);
		if (field) {
			if (typeof field === "function") return field(this, aValue, aEngine);
			else if (field) return new this(field);
		}
	}

	//#endregion Constructor
	//#region Information
	get fieldName() {
		return this.#fieldName;
	}
	static isFieldName_cls(aValue) {
		if (typeof aValue !== "string" && !(aValue instanceof String)) return false;
		let result = aValue.length > 2 && aValue[0] === "`" && aValue[aValue.length - 1] === "`";
		if (result) {
			result = !/=|\(|\)|<|>/g.test(aValue);
		}
		return result;
	}
	static tryFieldName_cls(aValue) {
		return this.isFieldName_cls(aValue) && aValue.substring(1, aValue.length - 1);
	}
	static get exprType() {
		return EXPRE_TYPE_FIELD;
	}
	static get kind() {
		return EXPRE_KIND_FIELD;
	}
	//#endregion Information
	//#region Management
	asValue(aInstance) {
		const itemGetter = aInstance?.itemOf;
		const result =
			typeof itemGetter === "function"
				? itemGetter.call(aInstance, this.#fieldName).finalField
				: aInstance?.valueOf(this.#fieldName);
		const getter = result && typeof result === "object" && result.asFilterValue;
		if (typeof getter === "function") {
			return getter.call(result);
		}
		return result;
	}
	asString() {
		return "`" + this.#fieldName + "`";
	}
	//#endregion Management
}
class MFExpressionValue extends MFExpressionBase {
	//#region Constructor
	#type;
	#value;
	constructor(aValue, aType) {
		const noValue = aValue === undefined || aValue === null || Number.isNaN(aValue) || aValue === "";
		let type = typeof aValue;
		if (type === "object") {
			if (type instanceof String) type = VALUE_TYPE_STRING;
			else if (type instanceof Number) type = VALUE_TYPE_NUMBER;
			else if (Array.isArray(aValue)) type = VALUE_TYPE_ARRAY;
		}
		if (noValue || type !== aType) {
			throw new Error(`ValueExpression expected params is [value,${aType}], but get [${aValue}, ${type}]!.`);
		}
		super();
		this.#type = aType;
		this.#value = aValue;
	}
	static tryCreate(aValue, aType, aAccepted) {
		if (aType === TOKEN_TYPE_LETTERS) {
			switch (aValue) {
				case "true":
					aValue = true;
				case "false":
					aValue = false;
					aType = VALUE_TYPE_BOOLEAN;
					break;
				case /^-?\d*\.?\d*$/.test(aValue) && (aValue.length === 1 || aValue[0] !== "0") && aValue:
					const value = Number.parseFloat(aValue);
					if (!Number.isNaN(value)) {
						aType = VALUE_TYPE_NUMBER;
						aValue = value;
						break;
					}
				default:
					aType = VALUE_TYPE_STRING;
			}
		} else if ((aType ?? undefined) === undefined) {
			const type = typeof aValue;
			switch (type) {
				case "string":
					aType = VALUE_TYPE_STRING;
					break;
				case "number":
					aType = VALUE_TYPE_NUMBER;
					break;
				case "boolean":
					aType = VALUE_TYPE_BOOLEAN;
					break;
				case "object":
					if (aValue instanceof String) aType = VALUE_TYPE_STRING;
					else if (aValue instanceof Number) aType = VALUE_TYPE_NUMBER;
					else if (aValue instanceof Boolean) aType = VALUE_TYPE_BOOLEAN;
					else if (Array.isArray(aValue)) aType = VALUE_TYPE_ARRAY;
					else aType = VALUE_TYPE_OBJECT;
					break;
				default:
					return false;
			}
		}
		const accept = aAccepted[EXPRE_TYPE_VALUE];
		if (accept !== true && accept !== aType) {
			if (typeof accept !== "function" || !accept(aType)) {
				return false;
			}
		}
		switch (aType) {
			case VALUE_TYPE_ARRAY:
				if (!Array.isArray(aValue)) {
					if (!String.isString(aValue)) return undefined;
					try {
						aValue = JSON.parse("[" + aValue + "]");
					} catch {
						return undefined;
					}
				}
				break;
			case VALUE_TYPE_NUMBER:
				aValue = Number.parseFloat(aValue);
				break;
		}
		return new this(aValue, aType);
	}
	//#endregion Constructor
	//#region Information
	get value() {
		return this.#value;
	}
	get type() {
		return this.#type;
	}
	static get exprType() {
		return EXPRE_TYPE_VALUE;
	}
	static get kind() {
		return EXPRE_KIND_VALUE;
	}
	//#endregion Information
	//#region Management
	asValue() {
		return this.#value;
	}
	asString() {
		switch (this.#type) {
			case VALUE_TYPE_STRING:
				return `"${this.#value}"`;
			case VALUE_TYPE_ARRAY:
				return `[${this.#value}]`;
			case VALUE_TYPE_OBJECT:
				return `${this.#value.toString()}`;
			default:
				return this.#value;
		}
	}
	//#endregion Management
}
//#endregion ExpressionsClasses
//#region PredicateClasses
//#region PredicateBaseClasses
class MFPredicateBase extends MFExpressionBase {
	//#region Information
	static get kind() {
		return EXPRE_KIND_REDICATE;
	}
	//#endregion Information
}
class MFPredicateOneArgs extends MFPredicateBase {
	//#region Constructor
	#value;
	constructor(...args) {
		if (args.length !== 1) {
			throw new Error(`Predicate ${this.exprType} need only 1 param!..`);
		}
		super(...args);
		this.#value = args[0];
	}
	static parse_cls(aEngine, aLeft, aRightStart, aString) {
		const predicate = new this(expressionOf(aEngine, aLeft, this[GET_ACCEPTED_EXPRES], this.exprType));
		return { predicate, end: aRightStart };
	}
	//#endregion Constructor
	//#region Information
	static get argumentsCount() {
		return 1;
	}
	//#endregion Information
	//#region Management
	get value() {
		return this.#value;
	}
	static [GET_ACCEPTED_EXPRES] = { [EXPRE_TYPE_FIELD]: true };
	//#endregion Management
}
class MFPredicateTwoArgs extends MFPredicateBase {
	//#region Constructor
	#left;
	#right;
	constructor(...args) {
		if (args.length !== 2) {
			throw new Error(`Predicate ${this.constructor.exprType} need 2 param!..`);
		}
		super(...args);
		this.#left = args[0];
		this.#right = args[1];
	}
	static parse_cls(aEngine, aLeft, aRightStart, aString) {
		const right = parseToken(aRightStart, aString);
		const accepted = this[GET_ACCEPTED_EXPRES];
		const leftExpr = expressionOf(aEngine, aLeft, accepted.left || accepted, `${this.exprType}.left`);
		const rightExpr = expressionOf(aEngine, right, accepted.right || accepted, `${this.exprType}.right`);
		const predicate = new this(leftExpr, rightExpr);
		return { predicate, end: right.end };
	}
	//#endregion Constructor
	//#region Information
	static get argumentsCount() {
		return 2;
	}
	//#endregion Information
	//#region Management
	get left() {
		return this.#left;
	}
	get right() {
		return this.#right;
	}
	static [GET_ACCEPTED_EXPRES] = {
		[EXPRE_TYPE_FIELD]: true,
		[EXPRE_TYPE_VALUE]: (type) => type !== VALUE_TYPE_ARRAY,
	};
	//#endregion Management
}
class MFPredicateThreeArgs extends MFPredicateBase {
	//#region Constructor
	#base;
	#min;
	#max;
	constructor(...args) {
		if (args.length !== 3) {
			throw new Error(`Predicate ${new.target.exprType} need 3 param!..`);
		}
		super(...args);
		this.#base = args[0];
		this.#min = args[1];
		this.#max = args[2];
	}
	static parse_cls(aEngine, aLeft, aRightStart, aString) {
		const type = this.exprType;
		const min = parseToken(aRightStart, aString);
		const combine = parseToken(min.end, aString);
		if (combine?.token !== this.combine) {
			const msg = `Predicate ${type} combine mode expected ${this.combine} but get ${combine?.token || combine}!..`;
			throw new Error(msg);
		}
		const max = parseToken(combine.end, aString);
		const accepted = this[GET_ACCEPTED_EXPRES];
		const baseExpr = expressionOf(aEngine, aLeft, accepted.base || accepted, `${type}.value`);
		const minExpr = expressionOf(aEngine, min, accepted.min || accepted, `${type}.min`);
		const maxExpr = expressionOf(aEngine, max, accepted.max || accepted, `${type}.max`);
		const predicate = new this(baseExpr, minExpr, maxExpr);
		return { predicate, end: max.end };
	}
	//#endregion Constructor
	//#region Information
	static get argumentsCount() {
		return 3;
	}
	//#endregion Information
	//#region Management
	get base() {
		return this.#base;
	}
	get min() {
		return this.#min;
	}
	get max() {
		return this.#max;
	}
	static get combine() {
		return COMBINE_TYPE_AND;
	}
	static [GET_ACCEPTED_EXPRES] = {
		base: { [EXPRE_TYPE_FIELD]: true },
		[EXPRE_TYPE_VALUE]: (type) => type !== VALUE_TYPE_ARRAY,
	};
	//#endregion Management
}
//#endregion PredicateBaseClasses
//#region CombineClasses
class MFPredicateCombine extends MFPredicateBase {
	//#region Constructor
	constructor(...args) {
		const left = args[0];
		const right = args[1];
		if (args.length !== 2 || !(left instanceof MFPredicateBase) || !(right instanceof MFPredicateBase)) {
			throw new Error(`Combine ${new.target.exprType} need tow predicate!..`);
		}
		super();
		this.left = left;
		this.right = right;
	}
	static parse_cls() {
		throw new Error("Combine method parse_cls must not called!..");
	}
	//#endregion Constructor
	//#region Information
	static get kind() {
		return EXPRE_KIND_COMBINE;
	}
	//#endregion Information
	//#region Management
	static [GET_ACCEPTED_EXPRES] = {};
	//#endregion Management
}
class MFPredicateAnd extends MFPredicateCombine {
	//#region Information
	static get exprType() {
		return COMBINE_TYPE_AND;
	}
	//#endregion Information
	//#region Management
	asValue(...args) {
		return this.left.asValue(...args) && this.right.asValue(...args);
	}
	asString() {
		return `(${this.left.asString()}) and (${this.right.asString()})`;
	}
	//#endregion Management
}
class MFPredicateOr extends MFPredicateCombine {
	//#region Information
	static get exprType() {
		return COMBINE_TYPE_OR;
	}
	//#endregion Information
	//#region Management
	asValue(...args) {
		return this.left.asValue(...args) || this.right.asValue(...args);
	}
	asString() {
		return `(${this.left.asString()}) or (${this.right.asString()})`;
	}
	//#endregion Management
}
//#endregion CombineClasses
//#region OperatorsClasses
class MFPredicateEqual extends MFPredicateTwoArgs {
	//#region Information
	static get exprType() {
		return "Equal";
	}
	static get symbol() {
		return "=";
	}
	//#endregion Information
	//#region Management
	asValue(...args) {
		const left = this.left.asValue(...args);
		const right = this.right.asValue(...args);
		return left == right;
	}
	asString() {
		return `${this.left.asString()} = ${this.right.asString()}`;
	}
	//#endregion Management
}
class MFPredicateNotEqual extends MFPredicateTwoArgs {
	//#region Information
	static get exprType() {
		return "NotEqual";
	}
	static get symbol() {
		return "!=";
	}
	//#endregion Information
	//#region Management
	asValue(...args) {
		return this.left.asValue(...args) != this.right.asValue(...args);
	}
	asString() {
		return `${this.left.asString()} != ${this.right.asString()}`;
	}
	//#endregion Management
}
class MFPredicateLessThan extends MFPredicateTwoArgs {
	//#region Information
	static get exprType() {
		return "LessThan";
	}
	static get symbol() {
		return "<";
	}
	//#endregion Information
	//#region Management
	asValue(...args) {
		return this.left.asValue(...args) < this.right.asValue(...args);
	}
	asString() {
		return `${this.left.asString()} < ${this.right.asString()}`;
	}
	//#endregion Management
}
class MFPredicateLessEqual extends MFPredicateTwoArgs {
	//#region Information
	static get exprType() {
		return "LessEqual";
	}
	static get symbol() {
		return "<=";
	}
	//#endregion Information
	//#region Management
	asValue(...args) {
		return this.left.asValue(...args) <= this.right.asValue(...args);
	}
	asString() {
		return `${this.left.asString()} <= ${this.right.asString()}`;
	}
	//#endregion Management
}
class MFPredicateGreaterThan extends MFPredicateTwoArgs {
	//#region Information
	static get exprType() {
		return "GreaterThan";
	}
	static get symbol() {
		return ">";
	}
	//#endregion Information
	//#region Management
	asValue(...args) {
		return this.left.asValue(...args) > this.right.asValue(...args);
	}
	asString() {
		return `${this.left.asString()} > ${this.right.asString()}`;
	}
	//#endregion Management
}
class MFPredicateGreaterEqual extends MFPredicateTwoArgs {
	//#region Information
	static get exprType() {
		return "GreaterEqual";
	}
	static get symbol() {
		return ">=";
	}
	//#endregion Information
	//#region Management
	asValue(...args) {
		return this.left.asValue(...args) >= this.right.asValue(...args);
	}
	asString() {
		return `${this.left.asString()} >= ${this.right.asString()}`;
	}
	//#endregion Management
}
//#endregion OperatorsClasses
//#region NullClasses
class MFPredicateIsNull extends MFPredicateOneArgs {
	//#region Information
	static get exprType() {
		return "IsNull";
	}
	static get symbol() {
		return "isNull";
	}
	//#endregion Information
	//#region Management
	asValue(...args) {
		return this.value.asValue(...args) === null;
	}
	asString() {
		return `${this.value.asString()} is null`;
	}
	static [GET_ACCEPTED_EXPRES] = { [EXPRE_TYPE_FIELD]: true };
	//#endregion Management
}
class MFPredicateIsNotNull extends MFPredicateOneArgs {
	//#region Information
	static get exprType() {
		return "IsNotNull";
	}
	static get symbol() {
		return "isNotNull";
	}
	//#endregion Information
	//#region Management
	asValue(...args) {
		return this.value.asValue(...args) !== null;
	}
	asString() {
		return `${this.value.asString()} is not null`;
	}
	static [GET_ACCEPTED_EXPRES] = { [EXPRE_TYPE_FIELD]: true };
	//#endregion Management
}
//#endregion NullClasses
//#region InClasses
class MFPredicateIn extends MFPredicateTwoArgs {
	//#region Information
	static get exprType() {
		return "In";
	}
	static get symbol() {
		return "in";
	}
	//#endregion Information
	//#region Management
	asValue(...args) {
		const right = this.right.asValue(...args);
		const includes = right?.includes;
		return typeof includes === "function" && includes.call(right, this.left.asValue(...args));
	}
	asString() {
		return `${this.left.asString()} in ${this.right.asString()}`;
	}
	static [GET_ACCEPTED_EXPRES] = {
		left: { [EXPRE_TYPE_FIELD]: true },
		right: { [EXPRE_TYPE_VALUE]: VALUE_TYPE_ARRAY },
	};
	//#endregion Management
}
class MFPredicateNotIn extends MFPredicateTwoArgs {
	//#region Information
	static get exprType() {
		return "NotIn";
	}
	static get symbol() {
		return "notIn";
	}
	//#endregion Information
	//#region Management
	asValue(...args) {
		const right = this.right.asValue(...args);
		const includes = right?.includes;
		return typeof includes === "function" && !includes.call(right, this.left.asValue(...args));
	}
	asString() {
		return `${this.left.asString()} not in ${this.right.asString()}`;
	}
	static [GET_ACCEPTED_EXPRES] = {
		left: { [EXPRE_TYPE_FIELD]: true },
		right: { [EXPRE_TYPE_VALUE]: VALUE_TYPE_ARRAY },
	};
	//#endregion Management
}
//#endregion InClasses
//#region BetweenClasses
class MFPredicateBetween extends MFPredicateThreeArgs {
	//#region Information
	static get exprType() {
		return "Between";
	}
	static get symbol() {
		return "<=>";
	}
	//#endregion Information
	//#region Management
	asValue(...args) {
		const base = this.base.asValue(...args);
		const min = this.min.asValue(...args);
		const max = this.max.asValue(...args);
		return base >= min && base <= max;
	}
	asString() {
		return `${this.base.asString()} between ${this.min.asString()} and ${this.max.asString()}`;
	}
	//#endregion Management
}
class MFPredicateNotBetween extends MFPredicateThreeArgs {
	//#region Information
	static get exprType() {
		return "NotBetween";
	}
	static get symbol() {
		return "><";
	}
	//#endregion Information
	//#region Management
	asValue(...args) {
		const base = this.base.asValue(...args);
		const min = this.min.asValue(...args);
		const max = this.max.asValue(...args);
		return base < min || base > max;
	}
	asString() {
		return `${this.base.asString()} not between ${this.min.asString()} and ${this.max.asString()}`;
	}
	//#endregion Management
}
//#endregion BetweenClasses
//#region StringClasses
class MFPredicateStringBase extends MFPredicateTwoArgs {
	//#region Management
	static [GET_ACCEPTED_EXPRES] = {
		left: { [EXPRE_TYPE_FIELD]: true },
		right: { [EXPRE_TYPE_VALUE]: VALUE_TYPE_STRING },
	};
	static regExp_cls(aValue) {
		const pattern = aValue.replace(/[.*+?{}()|[\]\\]/g, "\\$&");
		const regexPattern = pattern.replace(/%/g, ".*").replace(/_/g, ".");
		return new RegExp(`^${regexPattern}$`, "i");
	}
	//#endregion Management
}

class MFPredicateLike extends MFPredicateStringBase {
	//#region Information
	static get exprType() {
		return "Like";
	}
	static get symbol() {
		return "like";
	}
	//#endregion Information
	//#region Management
	asValue(...args) {
		const left = this.left.asValue(...args);
		const right = this.right.asValue(...args);
		return this.constructor.regExp_cls(right).test(left);
	}
	asString() {
		return `${this.left.asString()} like ${this.right.asString()}`;
	}
	//#endregion Management
}
class MFPredicateNotLike extends MFPredicateStringBase {
	//#region Information
	static get exprType() {
		return "NotLike";
	}
	static get symbol() {
		return "notLike";
	}
	//#endregion Information
	//#region Management
	asValue(...args) {
		const left = this.left.asValue(...args);
		const right = this.right.asValue(...args);
		return !this.constructor.regExp_cls(right).test(left);
	}
	asString() {
		return `${this.left.asString()} not like ${this.right.asString()}`;
	}
	//#endregion Management
}
//#endregion StringClasses
//#endregion PredicateClasses
//#region ParsersClasses
class MFParserNot {
	static parse_cls(aEngine, aLeft, aRightStart, aString) {
		const type = parseToken(aRightStart, aString);
		const predicates = aEngine[SYMBOL_NAMED_PREDICATES];
		switch (type.token) {
			case "in":
				return predicates.notIn.parse_cls(aEngine, aLeft, type.end, aString);
			case "between":
				return predicates.notBetween.parse_cls(aEngine, aLeft, type.end, aString);
			case "like":
				return predicates.notLike.parse_cls(aEngine, aLeft, type.end, aString);
			default:
				throw new Error(`not type ${type} was not treated!..`);
		}
	}
}
class MFParserIs {
	static parse_cls(aEngine, aLeft, aRightStart, aString) {
		const loc_create = (aClass, aEnd) => {
			const left = expressionOf(aEngine, aLeft, aClass[GET_ACCEPTED_EXPRES]);
			const predicate = new aClass(left);
			return { predicate, end: aEnd };
		};
		const predicates = aEngine[SYMBOL_NAMED_PREDICATES];
		const type = parseToken(aRightStart, aString);
		if (type.token === "not") {
			const sub_type = parseToken(type.end, aString);
			if (sub_type.token === "null") {
				return loc_create(predicates.isNotNull, sub_type.end);
			}
		}
		if (type.token === "null") {
			return loc_create(predicates.isNull, type.end);
		}
		throw new Error(`is type ${type} was not treated!..`);
	}
}
//#endregion ParsersClasses
class MFilterEngine {
	//#region Constructor
	constructor(...args) {
		const cls = this.constructor;
		this[SYMBOL_ALL_PREDICATES] = cls[SYMBOL_ALL_PREDICATES];
		this[SYMBOL_NAMED_PREDICATES] = cls[SYMBOL_NAMED_PREDICATES];
		this[SYMBOL_VALUE_EXPRESSION] = cls[SYMBOL_VALUE_EXPRESSION];
		this[SYMBOL_FIELD_EXPRESSION] = cls[SYMBOL_FIELD_EXPRESSION];
		if (args.length > 0) {
			this.#fixed = this.#predicateOf(...args);
		}
	}
	//#endregion Constructor
	//#region Predicates
	#fixed;
	#predicate;
	fixedWhere(...args) {
		const predicate = this.#predicateOf(...args);
		this.#fixed = predicate;
		this.#doChanged();
		return this;
	}
	fixedAnd(...args) {
		const predicate = this.#predicateOf(...args);
		if (!this.#fixed) this.#fixed = predicate;
		else this.#fixed = new this[SYMBOL_NAMED_PREDICATES].and(this.#fixed, predicate);
		this.#doChanged();
		return this;
	}
	fixedOr(...args) {
		const predicate = this.#predicateOf(...args);
		if (!this.#fixed) this.#fixed = predicate;
		else this.#fixed = new this[SYMBOL_NAMED_PREDICATES].or(this.#fixed, predicate);
		this.#doChanged();
		return this;
	}
	where(...args) {
		this.#predicate = this.#predicateOf(...args);
		this.#doChanged();
		return this;
	}
	and(...args) {
		const predicate = this.#predicateOf(...args);
		if (!this.#predicate) this.#predicate = predicate;
		else this.#predicate = new this[SYMBOL_NAMED_PREDICATES].and(this.#predicate, predicate);
		this.#doChanged();
		return this;
	}
	or(...args) {
		const predicate = this.#predicateOf(...args);
		if (!this.#predicate) this.#predicate = predicate;
		else this.#predicate = new this[SYMBOL_NAMED_PREDICATES].or(this.#predicate, predicate);
		this.#doChanged();
		return this;
	}
	get predicate() {
		if (this.#fixed && this.#predicate) {
			return new this[SYMBOL_NAMED_PREDICATES].and(this.#fixed, this.#predicate);
		}
		return this.#fixed || this.#predicate;
	}
	//#endregion Predicates
	//#region ChangedNotify
	#doChanged() {
		const onChanged = this.onChangedNoity;
		if (typeof onChanged === "function") {
			onChanged.call(this, this);
		}
	}
	onChanged(aCallback) {
		this.onChangedNoity = aCallback;
	}
	//#region ChangedNotify
	//#region ArgumentsBuilder
	#expressionOf(aValue, aAccepted) {
		const fieldClass = this[SYMBOL_FIELD_EXPRESSION];
		const fieldName = fieldClass.tryFieldName_cls(aValue);
		if (fieldName) {
			return fieldClass.create(this, fieldName, aAccepted, "expressionOf:");
		}
		let result = fieldClass.tryCreate(this, aValue, aAccepted);
		if (!result) result = this[SYMBOL_VALUE_EXPRESSION].tryCreate(aValue, undefined, aAccepted);
		if (!result) {
			throw new Error(`expressionOf: Can not create field or value for: ${aValue}!..`);
		}
		return result;
	}
	#predicateOf1(aValue) {
		//#region PossibleArguments
		// 1. CommandStirng.
		// 2. function.
		// 3. object.
		// 4. array: combine and.
		//#endregion PossibleArguments
		switch (typeof aValue) {
			case "string":
				return parsePredicate(this, aValue);
			case "function":
				const result = aValue(this.functionParams);
				if (!(result instanceof MFPredicateBase)) {
					throw new Error("function param must return predicate!.");
				}
				return result;
			case "object":
				if (Array.isArray(aValue)) return this.#predicateOf(...aValue);
				else {
					const obj = {};
					const allPredicates = this[SYMBOL_ALL_PREDICATES];
					Object.assign(obj, aValue);
					const Class = Object.hasOwn(obj, "operator") ? allPredicates[obj.operator] : allPredicates.equal;
					if (!(Class?.prototype instanceof MFPredicateTwoArgs)) {
						throw new Error(`ObjectPredicate: expected TwoArgs operator but get ${obj.operator} => ${Class}!..`);
					}
					const keyIsValue = obj.keyIsValue === true;
					const valueIsField = obj.valueIsField === true;
					const Combine = !(obj.and || obj.or === false) ? allPredicates.or : allPredicates.and;
					delete obj.operator;
					delete obj.keyIsValue;
					delete obj.valueIsField;
					delete obj.or;
					delete obj.and;
					let result;
					const accepted = Class[GET_ACCEPTED_EXPRES];
					const fieldClass = this[SYMBOL_FIELD_EXPRESSION];
					const valueClass = this[SYMBOL_VALUE_EXPRESSION];
					const fieldGetter = (aAccepted, aName) => fieldClass.create(this, aName, aAccepted);
					const valueGetter = (aAccepted, aValue) => valueClass.tryCreate(aValue, undefined, aAccepted);
					const leftGetter = (keyIsValue ? valueGetter : fieldGetter).bind(undefined, accepted.left || accepted);
					const rightGetter = (valueIsField ? fieldGetter : valueGetter).bind(undefined, accepted.right || accepted);
					for (let [key, value] of Object.entries(obj)) {
						const left = leftGetter(key);
						const right = rightGetter(value);
						if (!left || !right) {
							throw new Error(`ObjectPredicate: can not create expression for key [${key}:${value}]!..`);
						}
						const predicate = new Class(left, right);
						if (!result) result = predicate;
						else result = new Combine(result, predicate);
					}
					return result;
				}
			default:
				const error = `OneArguments predicate: expected param (commandString, object, array or function) but get ${aValue}!..`;
				throw new Error(error);
		}
	}
	#predicateOf(...args) {
		//#region PossibleArguments
		// 1. (left, right): operator equal.
		// 2. (left, operator, right).
		// 3. array:
		// 	3.1: ([left, right]): operator equal.
		// 	3.2: ([left, operator, right]).
		// 	3.3: ([[left, right], [left, operator, right]]): combine and.
		// 	3.4: ([[left, right], [left, operator, right]], combine).
		// 4. object: {left: right, left2: right2, keyIsValue:true|false, valueIsField:true|false, and:true|false, or:true|false}.
		// 5. function: param is operators, result is predicate.
		// 6. command: string command.
		// 7. merger of all before: combine and.
		//#endregion PossibleArguments
		const allPredicates = this[SYMBOL_ALL_PREDICATES];
		const Operator = args[1] && allPredicates[args[1]];
		switch (args.length) {
			case 0:
				throw new Error("can not get predicate with zero arguments!..");
			case 1:
				return this.#predicateOf1(...args);
			case 2:
				//#region PossibleArguments
				// 1. (key, isNull|isNotNull)
				// 2. (array, combine).
				// 3. (key, Value): operator and.
				//#endregion PossibleArguments
				if (Operator?.prototype instanceof MFPredicateOneArgs) {
					const accepted = Operator[GET_ACCEPTED_EXPRES];
					const value = this.#expressionOf(args[0], accepted);
					return new Operator(value);
				}
				if (Array.isArray(args[0]) && Operator?.prototype instanceof MFPredicateCombine) {
					let result;
					for (let element of args[0]) {
						if (!Array.isArray(element)) {
							throw new Error(`TowArguments predicate: array elements expected array but get ${element}!..`);
						}
						const predicate = this.#predicateOf(...element);
						if (!result) result = predicate;
						else result = new Operator(result, predicate);
					}
					return result;
				}
				const fieldClass = this[SYMBOL_FIELD_EXPRESSION];
				if (fieldClass.isFieldName_cls(args[0]) || fieldClass.isFieldName_cls(args[1])) {
					const Class = allPredicates.equal;
					const accepted = Class[GET_ACCEPTED_EXPRES];
					const left = this.#expressionOf(args[0], accepted.left || accepted);
					const right = this.#expressionOf(args[1], accepted.right || accepted);
					return new Class(left, right);
				} else {
					//try (Key, Value) operator and.
					const Class = allPredicates.equal;
					const accepted = Class[GET_ACCEPTED_EXPRES];
					const left = fieldClass.tryCreate(this, args[0], accepted.left || accepted);
					const right = this[SYMBOL_VALUE_EXPRESSION].tryCreate(args[1], undefined, accepted.right || accepted);
					if (left && right) return new Class(left, right);
				}
				break;
			case 3:
				if (Operator?.prototype instanceof MFPredicateTwoArgs) {
					const accepted = Operator[GET_ACCEPTED_EXPRES];
					const left = this.#expressionOf(args[0], accepted.left || accepted);
					const right = this.#expressionOf(args[2], accepted.right || accepted);
					return new Operator(left, right);
				}
				break;
			case 4:
				if (Operator?.prototype instanceof MFPredicateThreeArgs) {
					const accepted = Operator[GET_ACCEPTED_EXPRES];
					const base = this.#expressionOf(args[0], accepted.base || accepted);
					const min = this.#expressionOf(args[2], accepted.min || accepted);
					const max = this.#expressionOf(args[3], accepted.max || accepted);
					return new Operator(base, min, max);
				}
				break;
		}
		let result;
		let stop = args.length - 1;
		const last = allPredicates[args[stop]];
		const Combine = last?.prototype instanceof MFPredicateCombine ? last : allPredicates.and;
		if (Combine === last) stop--;
		for (let i = 0; i <= stop; i++) {
			const arg = args[i];
			const predicate = Array.isArray(arg) ? this.#predicateOf(...arg) : this.#predicateOf1(arg);
			if (!result) result = predicate;
			else result = new Combine(result, predicate);
		}
		return result;
	}
	#functionPredicates = undefined;
	get functionParams() {
		if (!this.#functionPredicates) {
			const result = {};
			const argValue = (value, accepted) => {
				if (value instanceof MFExpressionBase) {
					return value;
				}
				return this.#expressionOf(value, accepted);
			};
			for (const [key, Class] of Object.entries(this[SYMBOL_NAMED_PREDICATES])) {
				const func = (...args) => {
					if (args.length === 0) return new Class();
					const accepted = Class[GET_ACCEPTED_EXPRES];
					switch (args.length) {
						case 1:
							return new Class(argValue(args[0], accepted));
						case 2:
							const left = argValue(args[0], accepted.left || accepted);
							const right = argValue(args[1], accepted.right || accepted);
							return new Class(left, right);
						case 3:
							const base = argValue(args[0], accepted.base || accepted);
							const min = argValue(args[1], accepted.min || accepted);
							const max = argValue(args[2], accepted.max || accepted);
							return new Class(base, min, max);
						default:
							throw new Error(`args length ${args.length} was not treated!.`);
					}
				};
				result[key] = func;
			}
			this.#functionPredicates = result;
		}
		return this.#functionPredicates;
	}
	//#endregion ArgumentsBuilder
	//#region Management
	asString() {
		const predicate = this.predicate;
		if (!predicate) return undefined;
		return predicate.asString();
	}
	//#endregion Management
}
export class MFilter extends MFilterEngine {
	//#region Constructor
	constructor(aFieldGetter, ...args) {
		const type = typeof aFieldGetter;
		if (!aFieldGetter || (type !== "function" && type !== "object")) {
			throw new Error(`filter filedGetter param, expected types [function, object] but get ${type}!..`);
		}
		super();
		if (type === "function") this.fieldGetter = aFieldGetter;
		else {
			const searchIn = aFieldGetter;
			this.fieldGetter = (name) => name in searchIn && typeof searchIn[name] !== "function" && name;
		}
		if (args.length > 0) {
			this.fixedWhere(...args);
		}
	}
	//#endregion Constructor
	//#region Management
	static [SYMBOL_VALUE_EXPRESSION] = MFExpressionValue;
	static [SYMBOL_FIELD_EXPRESSION] = MFExpressionField;
	static [SYMBOL_NAMED_PREDICATES] = {
		//combine
		or: MFPredicateOr,
		and: MFPredicateAnd,
		// baseOperators
		equal: MFPredicateEqual,
		notEqual: MFPredicateNotEqual,
		less: MFPredicateLessThan,
		lessEqual: MFPredicateLessEqual,
		greater: MFPredicateGreaterThan,
		greaterEqual: MFPredicateGreaterEqual,
		//in array
		in: MFPredicateIn,
		notIn: MFPredicateNotIn,
		//between
		between: MFPredicateBetween,
		notBetween: MFPredicateNotBetween,
		//null
		isNull: MFPredicateIsNull,
		isNotNull: MFPredicateIsNotNull,
		//string
		like: MFPredicateLike,
		notLike: MFPredicateNotLike,
	};
	static [SYMBOL_PARSERS] = {
		is: MFParserIs,
		not: MFParserNot,
	};
	static [SYMBOL_ALL_PREDICATES] = (() => {
		const result = {
			...this[SYMBOL_NAMED_PREDICATES],
			...this[SYMBOL_PARSERS],
		};
		for (let [key, value] of Object.entries(this[SYMBOL_NAMED_PREDICATES])) {
			const symbol = value.symbol;
			if (symbol && symbol !== key) {
				result[symbol] = value;
			}
		}
		return result;
	})();
	//#endregion Management
	//#region Apply
	test(aInstance) {
		const predicate = this.predicate;
		if (!predicate) return true;
		return aInstance && typeof aInstance === "object" && predicate.test(aInstance);
	}
	apply(aArray) {
		if (!Array.isArray(aArray)) {
			throw new Error("apply method accept only array param, use test!.");
		}
		const predicate = this.predicate;
		if (!predicate) return aArray;
		return aArray.map((value) => {
			if (value && typeof value === "object" && predicate.test(value)) {
				return value;
			}
		});
	}
	//#endregion Apply
}
export function makeFilterEngine(aExtender) {
	const inherit = (name, owns, type, error) => {
		const result = aExtender(name, owns, type);
		if (!(result?.prototype instanceof owns)) {
			const msg = `extender for ${name} must return class!..`;
			if (error !== true) {
				console.warn(msg);
				return;
			}
			throw new Error(msg);
		}
		return result;
	};
	const FILTER_KIND = "filter";
	const Filter = inherit(FILTER_KIND, MFilterEngine, FILTER_KIND, true);
	Filter[SYMBOL_VALUE_EXPRESSION] = inherit(EXPRE_KIND_VALUE, MFilter[SYMBOL_VALUE_EXPRESSION], EXPRE_KIND_VALUE, true);
	Filter[SYMBOL_FIELD_EXPRESSION] = inherit(EXPRE_KIND_FIELD, MFilter[SYMBOL_FIELD_EXPRESSION], EXPRE_KIND_FIELD, true);
	const allPredicates = { ...MFilter[SYMBOL_PARSERS] };
	const namedPredicates = {};
	for (let [key, value] of Object.entries(MFilter[SYMBOL_NAMED_PREDICATES])) {
		const kind = value.kind;
		const result = inherit(key, value, kind, value.prototype instanceof MFPredicateCombine);
		if (result) {
			allPredicates[key] = result;
			namedPredicates[key] = result;
			const symbol = value.symbol;
			if (symbol && symbol !== key) {
				allPredicates[symbol] = result;
			}
		}
	}
	Filter[SYMBOL_ALL_PREDICATES] = allPredicates;
	Filter[SYMBOL_NAMED_PREDICATES] = namedPredicates;
	return Filter;
}
export default MFilter;
