import UIControllerList from "../UIControllersList.js";
import { UIField, UIDependsConfig } from "../UIMeta.js";
import ListField from "../../../shared/core/management/MListMngmt.js";

class UIListField extends ListField(UIField) {
	//#region ValueManagment
	#loadingID;
	#loadingList;
	doCreate(aParams) {
		const owner = this.instance;
		if (owner.isInDB) {
			const parentID = owner.pkValue;
			if (this.#loadingID === parentID) return this.#loadingList;
			this.#loadingID = parentID;
			this.#loadingList = this.#load(parentID, owner, aParams);
			return this.#loadingList;
		}
		this.#loadingID = undefined;
		this.#loadingList = undefined;
		return this.config.createList(owner, aParams);
	}
	//#endregion ValueManagment
	//#region LoadingManagement
	async #load(aParentID, aOwner, aParams) {
		const config = this.config;
		const result = config.createList(aOwner, aParams);
		if (!aParentID) {
			console.error("parentID is missing to get list");
			return result;
		}
		const getterClass = aOwner.constructor;
		const listName = config.name;
		const rows = await getterClass.apiGet(`${aParentID}/${listName}`);
		if (rows) {
			const instanceClass = await config.instanceClass;
			const loadParams = { receivedAt: this, loadStack: [], byList: true };
			for (let row of rows) {
				const instance = await instanceClass.createFromResponse(row, loadParams);
				result.push(instance);
			}
		}
		if (this.#loadingID === aParentID) {
			this.#loadingList = result;
		}
		return result;
	}
	//#endregion LoadingManagement
	//#region ApiManagement
	toApiRequest(aRequest) {
		const value = super.value;
		if ((value ?? undefined) === undefined) return;
		value.toApiRequest(aRequest);
	}
	async fromApiResponse(aValue, aResponse) {
		if (aValue) {
			if (!Array.isArray(aValue)) {
				throw Error(`list ${this.name} expected value array but get ${aValue}!.`);
			}
			const owner = this.instance;
			const list = this.config.createList(owner, aResponse);
			this.#loadingID = owner.pkValue;
			this.#loadingList = list;
			super.setValue(list, aResponse);
			await list.loadFromResponse(aValue, aResponse);
		} else {
			const old = super.value;
			this.#loadingID = undefined;
			this.#loadingList = undefined;
			super.setValue(undefined, aResponse);
			if (old) this.valueOrCreate();
		}
	}
	//#endregion ApiManagement
}

export class UIListConfig extends UIDependsConfig {
	//#region Constructor
	#use;
	#name;
	constructor(aName, aDeclaration) {
		if (!aName) {
			throw Error(`can not create listof without name!..`);
		}
		if (!aDeclaration.use) {
			throw Error(`can not create listof ${aName} without use property!..`);
		}
		if (!aDeclaration.listOf) {
			throw Error(`can not create listof ${aName} without controller!..`);
		}
		super(aDeclaration.listOf);
		this.#name = aName;
		this.#use = aDeclaration.use;
		this.title = aDeclaration.title || `fields.${aName}`;
	}
	//#endregion Constructor
	//#region Information
	get name() {
		return this.#name;
	}
	get useRelation() {
		return this.#use;
	}
	get fieldKind() {
		return "list";
	}
	//#endregion Information
	//#region MRtvManagement
	get rowsClass() {
		return this.instanceClass;
	}
	get fieldClass() {
		this.verifyInstanceClass();
		return UIListField;
	}
	createList(...args) {
		const controller = this.verifyInstanceClass();
		if (controller instanceof Promise) {
			return new Promise((resolve, reject) => {
				controller
					.then(() => {
						resolve(UIControllerList.create_cls(this, ...args));
					})
					.catch((e) => reject(e));
			});
		}
		return UIControllerList.create_cls(this, ...args);
	}
	//#endregion MRtvManagement
}

export default UIListConfig;
