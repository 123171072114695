import { isClass } from "@sys:utils";
import { DataTypes } from "@systypes";
export const MAPPLICATION_SYMBOLE = Symbol("MApplication");

export class AppBuilder {
	//#region Creator
	static createMeta_cls(...args) {
		throw new Error("AppBuilder.createMeta_cls was not interrupted!.");
	}
	//#endregion Creator
	//#region Controllers
	static prepareController(aName, aEntity, aApp) {
		if (Object.hasOwn(aEntity, "subApi")) {
			aEntity.api = `${aApp.apiRoot}/${aEntity.subApi}`;
		}
		aEntity.meta = this.createMeta_cls(aEntity, aName, aApp);
		Object.defineProperty(aEntity, "controller", {
			configurable: true,
			async get() {
				var controller = this.class;
				if (controller === undefined) {
					throw new Error(`Controller was not defined for ${this.meta.name}`);
				} else if (typeof controller !== "function") {
					throw new Error(`Controller must be function ${this.meta.name}`);
				} else if (!isClass(controller)) {
					controller = (await controller()).default;
				}
				this.controller = controller;
				return controller;
			},
			set(value) {
				value.meta = this.meta;
				delete this.meta;
				delete this.controller;
				this.controller = value;
				return value;
			},
		});
		return aEntity;
	}
	//#endregion Controllers
	//#region AppBuilding
	static async buildApp(aContainer, aApp) {
		const contains = aApp.contains;
		if (!contains) {
			return;
		}
		for (let [key, entity] of Object.entries(aApp.contains)) {
			if (entity === undefined || entity.class === undefined) {
				throw new Error(`Controller was not defined for ${key}`);
			}
			const controller = await this.prepareController(key, entity, aApp);
			aContainer.addController(key, controller);
		}
	}
	static async createApp(aApplication, aContainer, aClass) {
		const app = aClass.create_cls(aApplication, aContainer);
		const name = app.appName;
		if (name === undefined) {
			name = app.name;
		}
		app.name = name;
		aContainer.addApp(name, app);
		await this.buildApp(aContainer, app);
		return app;
	}
	//#endregion AppBuilding
}

export class ControllerBuilder {
	static treatRelationDefinition(aName, aColumn, aMeta, aParams) {
		throw new Error("treatRelationDefinition was not interrupted!.");
	}

	static treatColumnDefinition(aName, aColumn, aMeta, aParams) {
		throw new Error("treatColumnDefinition was not interrupted!.");
	}

	static treatTable(aMeta, aTableData, aParams) {
		for (let [name, value] of Object.entries(aTableData.columns)) {
			if (value.primaryKey && aParams.isPrimaryTable) {
				aMeta.setPrimaryKey(name);
			}
			if (value.relation) {
				this.treatRelationDefinition(name, value, aMeta, aParams);
			} else if (value.type) {
				this.treatColumnDefinition(name, value, aMeta, aParams);
			} else {
				throw new Error(`Unknown column definition for '${aParams.name}.${name}'!..`);
			}
			if (value.caption) {
				aMeta.addToCaption(name, value.caption);
			}
		}
	}

	static treatTables(aMeta, aData, aParams) {
		const tables = aData.tables;
		const tableParams = {};
		Object.assign(tableParams, aParams);
		tableParams.definitionMetaData = aData;
		tableParams.isPrimaryTable = true;
		if (tables === undefined) {
			const name = aData.tableName;
			if (!name) {
				if (aData.columns) {
					throw new Error(`tableName is required in meta ${aMeta.name}!..`);
				}
				if (!aMeta.parent?.tables) {
					throw new Error(`meta ${aMeta.name} must have one table at least!..`);
				}
				return;
			}
			tableParams.name = name;
			this.treatTable(aMeta, aData, tableParams);
		} else if (!Array.isArray(tables)) {
			throw new Error(`tables must be array for container ${aMeta.name}`);
		} else {
			if (tables.length === 1) {
				tableParams.name = tables[0].name;
				this.treatTable(aMeta, tables[0], tableParams);
				return;
			}
			var primaryTable = tables.find((t) => t.isPrimaryTable);
			if (!primaryTable) {
				throw new Error(`primaryTable for container ${aMeta.name} is required!.`);
			}
			tableParams.name = primaryTable.name;
			this.treatTable(aMeta, primaryTable, tableParams);
			delete tableParams.isPrimaryTable;
			tableParams.baseTable = primaryTable.name;
			for (let table of tables) {
				if (table !== primaryTable) {
					tableParams.name = table.name;
					this.treatTable(aMeta, table, tableParams);
				}
			}
		}
	}
	static treatAliases(aMeta, aData) {
		const aliases = aData.aliases;
		if (aliases) {
			for (let [key, value] of Object.entries(aliases)) {
				aMeta.addAlias(key, value);
			}
		}
	}

	static treatListFieldDefinition(aName, aDefinition, aMeta) {
		throw new Error("treatListFieldDefinition was not interrupted!.");
	}
	static treatFieldDefinition(aName, aDefinition, aMeta) {
		if (aDefinition.listOf) {
			this.treatListFieldDefinition(...arguments);
		} else if (aDefinition.type) {
			aMeta.addTypedField(aName, aDefinition.type, aDefinition);
		} else {
			throw new Error(`Unkown definition of field: ${aMeta.name}.${aName}`);
		}
	}
	static treatFields(aMeta, aData) {
		const fields = aData.fields;
		if (fields) {
			for (let [key, value] of Object.entries(fields)) {
				if (typeof value !== "object") {
					console.error(`field definition for Key: ${aMeta.name}.${key}, must be object!.`);
				} else {
					this.treatFieldDefinition(key, value, aMeta);
				}
			}
		}
	}

	static treatMetaData(aMeta /*, aData, aParams*/) {
		this.treatTables(...arguments);
		this.treatFields(...arguments);
		this.treatAliases(...arguments);
	}
	static initBuildMetaParams(aControllerClass, aContainer, aBuilder) {
		const result = {
			DataTypes,
			Builder: aBuilder,
			Container: aContainer,
			Controllers: aContainer.Controllers,
			ControllerClass: aControllerClass,
		};
		return result;
	}
	static buildMeta(aControllerClass, aContainer, aBuilder, aStartAt) {
		var meta = Object.hasOwn(aControllerClass, "meta") ? aControllerClass.meta : undefined;
		if (meta?.builded) {
			return meta;
		}
		let parentMeta = undefined;
		const parent = Object.getPrototypeOf(aControllerClass);
		if (this.canBuild(parent)) {
			parentMeta = this.buildMeta(parent, aContainer, aBuilder, aStartAt || aControllerClass);
			if (parentMeta === meta) {
				//backend Reintroduce
				return meta;
			}
		}
		const lParams = this.initBuildMetaParams(aControllerClass, aContainer, aBuilder);
		let metaData;
		if (Object.hasOwn(aControllerClass, "getMetaData")) {
			metaData = aControllerClass.getMetaData(lParams);
		}
		if (meta === undefined) {
			//in front or api Reintroduce.
			if (!metaData && !parentMeta) {
				aControllerClass.meta = null;
				return null;
			}
			const entity = aContainer.Controllers[aControllerClass.name];
			if (entity === undefined) {
				throw new Error(`Controller ${aControllerClass.name} was not registered!`);
			}
			meta = entity.meta;
			if (meta) {
				if (entity.prototype instanceof aControllerClass) {
					//backend class Reintroduce
					aControllerClass.meta = meta;
				} else {
					//frontend
					entity.controller = aControllerClass;
				}
				aControllerClass.meta = meta;
			} else {
				if (!aStartAt) {
					throw new Error(`Can't initiate meta for class:${aControllerClass.name}!...`);
				}
				meta = aStartAt.meta.createWithCopyInfo();
			}
		}
		return meta.build(metaData, parentMeta, this.treatMetaData.bind(this), lParams);
	}

	//#region BuildClasses
	static #stop;
	static canBuild(aClass) {
		return aClass !== ControllerBuilder.#stop;
	}
	static stopAt(aClass) {
		if (this.#stop) {
			throw new Error("stop class was already seated!..");
		}
		this.#stop = aClass;
	}
	//#endregion BuildClasses
}

export class MApplication {
	//#region Constructor
	#container = {
		Apps: {},
		Controllers: {},
		addApp(aName, aObject) {
			if (this.Apps[aName] !== undefined) {
				throw new Error(`App ${aName} was already registred.`);
			}
			this.Apps[aName] = aObject;
		},
		addController(aName, aController) {
			if (this.Controllers[aName] !== undefined) {
				throw new Error(`Controller ${aName} was already registred.`);
			}
			this.Controllers[aName] = aController;
		},
	};
	constructor() {
		this.utils = new ControllersUtils(this);
	}
	//#endregion constructor
	//#region ContainerContent
	get Apps() {
		return this.#container.Apps;
	}
	get Controllers() {
		return this.#container.Controllers;
	}
	valueOf(aKey) {
		if (aKey === ".") {
			return this.#container;
		} else {
			return this.#container[aKey];
		}
	}
	define(aKey, aValue) {
		if (this.#container[aKey] !== undefined) {
			throw new Error(`Key ${aKey} was already defined!.`);
		}
		if (aValue === undefined) {
			aValue = null;
		}
		this.#container[aKey] = aValue;
		Object.defineProperty(Object.getPrototypeOf(this), aKey, {
			get() {
				return this.#container[aKey];
			},
		});
	}
	//#region ContainerContent
	//#region AppsManagement
	async registerApp(aModule) {
		let appClass;
		if (aModule instanceof Promise) {
			appClass = (await aModule).default;
		} else if (typeof aModule === "string") {
			appClass = (await import(/* webpackIgnore: true */ `${aModule}`)).default;
		} else if (isClass(aModule)) {
			appClass = aModule;
		} else {
			throw new Error(`Unkown type of module ${aModule}!.`);
		}
		const result = this.AppBuilder.createApp(this, this.#container, appClass);
		result[MAPPLICATION_SYMBOLE] = this;
		return result;
	}
	async registerApps(aModules) {
		const appsCreatros = [];
		if (!Array.isArray(aModules)) {
			appsCreatros.push(await this.registerApp(aModules));
		} else {
			await Promise.all(aModules.map(async (module) => appsCreatros.push(await this.registerApp(module))));
		}
		return Promise.all(appsCreatros);
	}
	//#endregion AppsManagement
	//#region ControllersManagement
	buildController(aController, aBuilder) {
		const app = aController[MAPPLICATION_SYMBOLE];
		if (!app) {
			aController[MAPPLICATION_SYMBOLE] = this;
		} else if (app !== this) {
			throw new Error(`Controller was not belong to this application!.`);
		}
		return this.ControllerBuilder.buildMeta(aController, this.#container, aBuilder);
	}
	//#endregion ControllersManagement
	//#region abstract methods, must be setting by final application
	get AppBuilder() {
		return this.constructor.AppBuilder;
	}
	get ControllerBuilder() {
		return this.constructor.ControllerBuilder;
	}
	static get AppBuilder() {
		throw new Error("method MApplication.AppBuilder bust be reintroduced!.");
	}
	static get ControllerBuilder() {
		throw new Error("method MApplication.ControllerBuilder bust be reintroduced!.");
	}
	//#endregion abstract methods.
}

class ControllersUtils {
	//#region Constructor
	#application;
	constructor(aApplication) {
		this.#application = aApplication;
	}
	//#endregion Constructor
	//#region Controllers
	waitController(aName, aThen, aCatch, aFinally) {
		if (typeof aThen !== "function") {
			throw new Error(`waitController then{secondParam} param, expected type function but get '${typeof aThen}'!..`);
		}
		const controller = this.getController(aName);
		if (controller instanceof Promise) {
			controller.then = aThen;
			if (aCatch) controller.catch = aCatch;
			if (aFinally) controller.finally = aFinally;
		} else {
			aThen(controller);
			if (aFinally) aFinally();
		}
	}
	getController(aName) {
		let result = this.#application.Controllers[aName];
		if (!result) throw new Error(`Controller ${aName} was not registered!.`);
		if (!isClass(result)) {
			//frontend
			result = result.controller;
			if (result instanceof Promise) {
				return new Promise(async (resolve, reject) => {
					try {
						const controller = await result;
						controller.buildMeta();
						resolve(controller);
					} catch (e) {
						reject(e);
					}
				});
			}
		}
		return result;
	}
	getControllers(aNames) {
		if (!Array.isArray(aNames)) {
			throw new Error(`getControllers param, expected value is array of string but get ${aNames}!.`);
		}
		return aNames.map((name) => this.getController(name));
	}
	//#endregion Controllers
	//#region InstanceOf
	waitInstanceOf(aController, aThen, aCatch, aFinally, ...args) {
		if (typeof aThen !== "function") {
			throw new Error(`waitInstanceOf then{secondParam} param, expected type function but get '${typeof aThen}'!..`);
		}
		const instance = this.newInstanceOf(aController, ...args);
		if (instance instanceof Promise) {
			instance.then = aThen;
			if (aCatch) instance.catch = aCatch;
			if (aFinally) instance.finally = aFinally;
		} else {
			aThen(instance);
			if (aFinally) aFinally();
		}
	}
	newInstanceOf(aController, ...args) {
		const controller = this.getController(aController);
		if (controller instanceof Promise) {
			const result = new Promise((resolve, reject) =>
				controller.then((cls) => resolve(cls.create_cls(...args)).catch((e) => reject(e)))
			);
			return result;
		}
		return controller.create_cls(...args);
	}
	//#endregion InstanceOf
	//#region ControllerLoading
	waitLoadByPK(aController, aPrimaryKey, aThen, aCatch, aFinally, aStrategy, ...args) {
		return new Promise(async (resolve, reject) => {
			try {
				const controller = await this.getController(aController);
				const result = await controller.loadByPK(aPrimaryKey, aStrategy, ...args);
				if (aThen) aThen(result);
				resolve(result);
			} catch (e) {
				if (aCatch) aCatch(e);
				else reject(e);
			} finally {
				if (aFinally) aFinally();
			}
		});
	}
	async loadByPK(aController, aPrimaryKey, aStrategy, ...args) {
		const controller = await this.getController(aController);
		return await controller?.loadByPK(aPrimaryKey, aStrategy, ...args);
	}
	waitLoadByFilter(aController, aStrategy, aFilter, aThen, aCatch, aFinally, ...args) {
		return new Promise(async (resolve, reject) => {
			try {
				const controller = await this.getController(aController);
				const result = await controller.loadByFilter(aStrategy, aFilter, ...args);
				if (aThen) aThen(result);
				resolve(result);
			} catch (e) {
				if (aCatch) aCatch(e);
				else reject(e);
			} finally {
				if (aFinally) aFinally();
			}
		});
	}
	async loadByFilter(aController, aStrategy, aFilter, ...args) {
		const controller = await this.getController(aController);
		return await controller?.loadByFilter(aStrategy, aFilter, ...args);
	}
	//#endregion ControllerLoading
}

export var GlobalApplication;

export function createGlobalApplication(aClass) {
	if (GlobalApplication) {
		throw new Error("Global Application was already created!!.");
	}
	GlobalApplication = new (aClass || MApplication)();
	global.MApplication = GlobalApplication;
	return GlobalApplication;
}
export default createGlobalApplication;
