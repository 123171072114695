import { MFilter, MRtv_OBJECT_CONFIGS, MRtvUtils } from "@systypes";

let FieldClass;
function filterFieldClass(aBase) {
	if (!FieldClass) {
		FieldClass = class extends aBase {
			//#region Constructor
			constructor(aPath) {
				let path = "";
				let config = aPath.rootConfig;
				MRtvUtils.enumPaths(aPath, (aKey, aConfig) => {
					path += aKey + ".";
					config = aConfig;
					return true;
				});
				path = path.slice(0, -1);
				super(path);
				const asString = config.asFilterString;
				if (typeof asString === "function") {
					this.asString = () => asString(path);
				}
			}
			//#endregion Constructor
		};
	}
	return FieldClass;
}

export function FilterableField(aBase) {
	class Filterable extends aBase {
		#filter;
		get filter() {
			if (!this.#filter) {
				const configs = this.config[MRtv_OBJECT_CONFIGS];
				const fieldGetter = (name) => {
					const path = MRtvUtils.parsePath(configs, name);
					if (!MRtvUtils.isUseablePath(path, false)) return false;
					return (aFieldClass) => new (filterFieldClass(aFieldClass))(path);
				};
				this.#filter = new MFilter(fieldGetter);
				let applyTimer;
				let lastApplied;
				this.#filter.onChanged(() => {
					if (applyTimer) clearTimeout(applyTimer);
					applyTimer = setTimeout(() => {
						const str = this.#filter.asString();
						if (str !== lastApplied) {
							lastApplied = str;
							this.doFilterChanged(this.#filter);
						}
					});
				});
			}
			return this.#filter;
		}
		get filterDirect() {
			return this.#filter;
		}
		clearFilter() {
			if (this.#filter) {
				this.#filter = undefined;
				this.doFilterChanged(undefined);
			}
		}
		doFilterChanged(aFilter) {
			if (aFilter && !aFilter.test(super.value)) {
				super.setValue(null, { clearedByFilter: true });
			}
		}
		setValue(aValue, aParams, ...args) {
			if (aParams?.byResponse || aParams?.byRequest || aParams?.inNew || aParams?.dbLoading) {
				return super.setValue(...arguments);
			}
			const filter = this.#filter;
			if (filter && !filter.test(aValue)) {
				console.info(`Value for field ${this.name}: `, aValue, ` auto cleared by filter: ${filter.asString()}.`);
				const params = { clearedByFilter: true, settingParams: aParams };
				return super.setValue(null, params, ...args);
			}
			return super.setValue(...arguments);
		}
		asFilterValue() {
			const value = super.value;
			if (value && typeof value === "object") {
				return value.pkValue ?? value;
			}
			return value;
		}
	}
	return Filterable;
}
