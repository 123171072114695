import { MRtvUtils } from "@systypes";
import { counterID } from "../../shared/utils/miscellaneous.js";
import { GlobalApplication, MAPPLICATION_SYMBOLE, ControllerBuilder } from "./MApplication.js";
import { Validator, revalidateField, VALIDATION_REASONS, REVALIDATE_SYMBOL } from "./validation/MVCore.js";

const DEFAULT_VALUES_LASTVALUE_SYMBOL = Symbol();
export const CLASS_STATUS = {
	inDB: 1,
	inNew: 2,
	newed: 4,
	inLoad: 8,
	loaded: 16,
	inAdd: 32,
	inUpdate: 64,
	inDelete: 128,
	added: 256,
	updated: 512,
	deleted: 1024,
	symbol: Symbol(),
};

export default (Base) => {
	class MController extends Base {
		//#region Creator
		constructor() {
			super(/* aConfig */);
			const primaryField = this.meta.primaryField;
			this[primaryField].setValue(counterID("id"), { autoInitiate: true });
		}
		//#endregion Creator
		//#region Status
		#status = 0;
		get isInDB() {
			return (this.#status & CLASS_STATUS.inDB) === CLASS_STATUS.inDB;
		}
		get isOutDB() {
			return (this.#status & CLASS_STATUS.inDB) === 0;
		}
		get isInNew() {
			return (this.#status & CLASS_STATUS.inNew) === CLASS_STATUS.inNew;
		}
		get isNewed() {
			return (this.#status & CLASS_STATUS.newed) === CLASS_STATUS.newed;
		}
		get isInLoad() {
			return (this.#status & CLASS_STATUS.inLoad) === CLASS_STATUS.inLoad;
		}
		get isLoaded() {
			return (this.#status & CLASS_STATUS.loaded) === CLASS_STATUS.loaded;
		}
		get isInAdd() {
			return (this.#status & CLASS_STATUS.inAdd) === CLASS_STATUS.inAdd;
		}
		get isAdded() {
			return (this.#status & CLASS_STATUS.added) === CLASS_STATUS.added;
		}
		get isInUpdate() {
			return (this.#status & CLASS_STATUS.inUpdate) === CLASS_STATUS.inUpdate;
		}
		get isUpdated() {
			return (this.#status & CLASS_STATUS.updated) === CLASS_STATUS.updated;
		}
		get isInDelete() {
			return (this.#status & CLASS_STATUS.inDelete) === CLASS_STATUS.inDelete;
		}
		get isDeleted() {
			return (this.#status & CLASS_STATUS.deleted) === CLASS_STATUS.deleted;
		}
		[CLASS_STATUS.symbol](aNew) {
			switch (aNew) {
				case CLASS_STATUS.inAdd:
				case CLASS_STATUS.inUpdate:
				case CLASS_STATUS.inLoad:
				case CLASS_STATUS.added:
				case CLASS_STATUS.updated:
				case CLASS_STATUS.loaded:
					aNew = aNew | CLASS_STATUS.inDB;
					break;
				case CLASS_STATUS.inDB:
				case CLASS_STATUS.inNew:
				case CLASS_STATUS.newed:
				case CLASS_STATUS.inDelete:
				case CLASS_STATUS.deleted:
					break;
				default:
					throw new Error(`instance status ${aNew} was not treated!..`);
			}
			this.#status = aNew;
		}
		//#endregion Status
		//#region Information
		get meta() {
			return this.constructor.meta;
		}
		get api() {
			return this.meta.api;
		}
		get application() {
			return this.constructor.application;
		}
		get controllersUtils() {
			return this.application.utils;
		}
		static get api() {
			return this.meta.api;
		}
		static get application() {
			return this[MAPPLICATION_SYMBOLE];
		}
		static get controllersUtils() {
			return this.application.utils;
		}
		//#endregion Information
		//#region PrimaryKeyManagement
		pkFilterAsArray(aOperator = "=") {
			const primaryKey = this.meta.primaryKey;
			return ["`" + primaryKey + "`", aOperator || "=", this.pkValue];
		}
		//#endregion PrimaryKeyManagement
		//#region FilterMethods
		mngmtFilter(aExcludePrimaryKey) {
			const result = [];
			if (aExcludePrimaryKey) {
				result.push(this.pkFilterAsArray("!="));
			}
			return result;
		}
		//#endregion FilterMethods
		//#region MetaManagement
		static buildMeta() {
			var meta = Object.hasOwn(this, "meta") ? this.meta : undefined;
			if (meta !== undefined && meta.builded === undefined) {
				return meta;
			}
			const application = this.application || GlobalApplication;
			if (!application) {
				throw Error("global application was not created yet!.");
			}
			return application.buildController(this);
		}
		static getMetaData() {
			return undefined;
		}
		//#endregion MetaManagement
		//#region Validation
		revalidateByField(aField, aValue, aParams) {
			if (aParams?.byResponse || aParams?.byRequest || aParams?.inNew || aParams?.dbLoading) return false;
			return revalidateField(this, aField);
		}
		revalidate(aFields) {
			delete this[REVALIDATE_SYMBOL];
			const reason = this.isInDB ? VALIDATION_REASONS.update : VALIDATION_REASONS.add;
			const validator = new Validator(this, reason, aFields);
			return this.validateBase(validator);
		}
		async validate(aReason) {
			if (!aReason || typeof aReason !== "string") {
				throw new Error(`validate param expected param is reason but get ${aReason}!..`);
			}
			delete this[REVALIDATE_SYMBOL];
			const validator = new Validator(this, aReason);
			return await this.validateBase(validator);
		}
		async doValidate(aValidator) {
			//developers override
		}
		async validateBase(aValidator) {
			//validate instance
			delete this[REVALIDATE_SYMBOL];
			const funcs = [this.doValidate(aValidator)];
			funcs.push(...this.meta.validate(aValidator, this));
			for (let field of Object.values(this)) {
				const func = field?.validate;
				if (typeof func === "function") {
					funcs.push(func.call(field, aValidator));
				}
			}
			await Promise.all(funcs);
			return aValidator.approveAt(this);
		}
		//#endregion Validation
		//#region New
		async #newGetter(aParams) {
			const values = {};
			aParams.fieldsValues = values;
			const constructor = this.constructor;
			values[this.meta.primaryField] = counterID("id");
			const defaults = await constructor.defaultValues();
			if (defaults) {
				aParams.defaults = defaults;
				for (let [name, value] of Object.entries(defaults)) {
					values[name] = value;
				}
			}
			const newValues = this.getNewValues;
			if (typeof newValues === "function") {
				const vls = await newValues.call(this, aParams);
				if (!vls || typeof vls !== "object") {
					throw new Error("getNewValues must return object result!.");
				}
				for (let [name, value] of Object.entries(vls)) {
					values[name] = value;
				}
			}
			return await constructor.meta.applyNew(this, aParams);
		}
		async new() {
			try {
				this[CLASS_STATUS.symbol](CLASS_STATUS.inNew);
				const newParams = { inNew: true };
				const beforeNew = this.beforeNew;
				if (typeof beforeNew === "function") {
					newParams.beforeResult = await beforeNew.call(this, newParams);
				}
				await this.#newGetter(newParams);
				await this.doNew(newParams);
				const afterNew = this.afterNew;
				if (typeof afterNew === "function") {
					await afterNew.call(this, newParams);
				}
				this.validate(VALIDATION_REASONS.add);
				this[CLASS_STATUS.symbol](CLASS_STATUS.newed);
			} catch (e) {
				throw e;
			}
		}
		async doNew(aParams) {
			const { fieldsValues = {} } = aParams;
			const funcs = [];
			const fields = this.meta.allStrategyFields(true, true, false, false);
			for (let name of fields) {
				const field = this[name];
				if (field?.isOwnerValue === true) continue;
				const setter = field?.newSetter || field?.setValue;
				if (typeof setter === "function") {
					funcs.push(setter.call(field, fieldsValues[name], aParams));
				}
			}
			await Promise.all(funcs);
		}
		async lastValueOf(aField, aFilter) {
			const filter = this.mngmtFilter(false);
			if (filter.length === 0) {
				return this.constructor.lastValueOf(aField, aFilter);
			}
			if (aFilter) filter.push(aFilter);
			return this.constructor.lastValueOf(aField, filter);
		}
		async defaultValues() {
			return this.constructor.defaultValues();
		}
		static async defaultValues(aForce) {
			const getter = this.defaultValuesGetter;
			if (typeof getter !== "function") return;
			const current = Date.now();
			let last = this[DEFAULT_VALUES_LASTVALUE_SYMBOL];
			if (aForce === true || !last || current - last.time > 10 * 60 * 1000) {
				if (!last) {
					last = {};
					this[DEFAULT_VALUES_LASTVALUE_SYMBOL] = last;
				}
				last.result = await getter.call(this);
				last.time = Date.now();
			}
			return last.result;
		}
		static async reloadDefaults() {
			return await this.defaultValues(true);
		}
		//#endregion New
		//#region Strategies
		static strategies(name) {
			switch (name) {
				case "all":
					return this.buildMeta().allStrategyFields(false, true, true, true);
				case "allWithRelations":
					return this.buildMeta().allStrategyFields(false, true, false, true);
				case "default":
					return this.buildMeta().defaultStrategyFields;
				case "uiList":
					return {
						use: "all",
						exclude(func) {
							const primaryKey = this.meta.primaryKey;
							if (primaryKey) func(primaryKey);
						},
					};
			}
		}
		static #mergeStrategies(aDest, aSource) {
			if (aSource.include) {
				if (Array.isArray(aSource.include)) {
					aDest.fields.push.call(aDest.fields, ...aSource.include);
				} else {
					aDest.fields.push(aSource.include);
				}
			}
			const exclude = aSource.exclude;
			if (exclude) {
				const fields = aDest.fields;
				const doExclude = (name) => {
					const index = fields.indexOf(name);
					if (index >= 0) fields.splice(index, 1);
					return index;
				};
				switch (typeof exclude) {
					case "string":
						doExclude(exclude);
						break;
					case "function":
						exclude.call(this, doExclude);
						break;
					case "object":
						if (Array.isArray(exclude)) {
							exclude.forEach(doExclude);
							break;
						} else if (exclude instanceof String) {
							doExclude(exclude);
							break;
						}
					default:
						throw new Error(`Strategy exclude must be (fieldName, function or array of fields)!..`);
				}
			}
			if (aSource.filter) {
				if (!aDest.filter) aDest.filter = aSource.filter;
				else aDest.filter = [aSource.filter, result.filter];
			}
		}
		static #applyStrategyExtenders(aName, aDest) {
			const func = this.extendStrategy;
			if (typeof func !== "function") return aDest;
			const merge = MController.#mergeStrategies;
			const params = {
				include: (aValue) => merge.call(this, aDest, { include: aValue }),
				exclude: (aValue) => merge.call(this, aDest, { exclude: aValue }),
				filter: (aValue) => merge.call(this, aDest, { filter: aValue }),
			};
			func.call(this, aName, params);
			return aDest;
		}
		static buildStrategy(aStrategy) {
			if (!aStrategy || typeof aStrategy !== "object") {
				throw new Error("buildStrategy param must be object!..");
			}
			if (!!aStrategy.use === !!aStrategy.fields && !aStrategy.use) {
				throw new Error(`Strategy object definition must have 'use' or 'fields'!..`);
			}
			if (!aStrategy.use) {
				return aStrategy;
			}
			const result = this.findStrategy(aStrategy.use);
			MController.#mergeStrategies.call(this, result, aStrategy);
			return result;
		}
		static findStrategy(aStrategy) {
			let cls = this;
			let stop = Object.getPrototypeOf(Base);
			let strategy;
			do {
				if (Object.hasOwn(cls, "strategies")) {
					const strategies = cls.strategies;
					if (strategies) {
						switch (typeof strategies) {
							case "object":
								strategy = strategies[aStrategy];
								break;
							case "function":
								strategy = strategies.call(this, aStrategy);
								break;
						}
						if (strategy) {
							break;
						}
					}
				}
				cls = Object.getPrototypeOf(cls);
			} while (cls && cls !== stop);
			if (!strategy) {
				throw Error(`Strategy ${aStrategy} is not defined into ${this.name}!..`);
			}
			if (Array.isArray(strategy)) {
				return MController.#applyStrategyExtenders.call(this, aStrategy, { fields: strategy });
			}
			if (typeof strategy === "object") {
				return MController.#applyStrategyExtenders.call(this, aStrategy, this.buildStrategy(strategy));
			}
			throw new Error(`Strategy ${aStrategy} must be object or array of fields!..`);
		}
		static forEachStrategyField(aStrategy, aCallback) {
			const fields = this.findStrategy(aStrategy).fields;
			const configs = this.buildMeta().configs;
			let i = 0;
			const length = fields.length;
			for (i; i < length; i++) {
				const name = fields[i];
				const path = MRtvUtils.parsePath(configs, name);
				if (path instanceof Promise) break;
				const config = MRtvUtils.isUseablePath(path, false) ? MRtvUtils.getPathConfig(path) : undefined;
				aCallback(name, config, path);
			}
			if (i < length) {
				return (async () => {
					for (i; i < length; i++) {
						const name = fields[i];
						const path = await MRtvUtils.parsePath(configs, name);
						const config = MRtvUtils.isUseablePath(path, false) ? MRtvUtils.getPathConfig(path) : undefined;
						aCallback(name, config, path);
					}
					return true;
				})();
			}
			return true;
		}
		//#endregion Strategies
	}
	ControllerBuilder.stopAt(MController);
	return MController;
};
