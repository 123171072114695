import { MRtvList } from "@systypes";
import { VALIDATION_REASONS } from "./validation/MVCore.js";
export class MControllerList extends MRtvList {
	//#region Constructor
	#owner;
	constructor(aConfig, aOwner) {
		super(aConfig);
		const use = this.config.useRelation;
		if (!aOwner || !use) {
			throw new Error("ControllersList need owner and useField!..");
		}
		this.#owner = aOwner;
	}
	//#endregion
	//#region Information
	get owner() {
		return this.#owner;
	}
	get instanceClass() {
		return this.config.instanceClass;
	}
	//#endregion Information
	//#region Management
	createNew(...args) {
		const useThis = this.original;
		const result = useThis.instanceClass.create_cls(...args);
		useThis.#initInstance(result, ...args);
		return result;
	}
	#initInstance(aValue, aParams) {
		const owner = this.#owner;
		if (owner && aValue && typeof aValue === "object") {
			const use = this.config.useRelation;
			if (use) {
				const field = aValue[use];
				if (!field) {
					throw new Error(`instance doesn't has ${use} field!..`);
				}
				if (!aParams || typeof aParams !== "object") aParams = {};
				aParams.settedBy = this;
				aParams.isOwnerValue = true;
				field.setValue(owner, aParams);
			}
		}
	}
	doPropertyChanged(aKey, aValue, ...args) {
		aValue && this.#initInstance(aValue);
	}
	//#endregion Management
	//#region Miscellaneous
	addNew(...args) {
		const result = this.createNew(...args);
		this.push(result);
		return result;
	}
	//#endregion Miscellaneous
	//#region Validation
	async validate(aValidator, aName, aTitleFunc) {
		const validateInstance = async (instance, index, reason) => {
			const result = await instance.validate(reason);
			if (result) {
				const formatter = (...args) => {
					return `${aTitleFunc(...args)}[${index}]`;
				};
				aValidator.addArticles(`${aName}[${index}]`, formatter, result);
			}
			return result;
		};
		const alls = [];
		const listReason = aValidator.reason;
		this.forEach((instance, index) => {
			if (!instance) return;
			let reason;
			switch (listReason) {
				case VALIDATION_REASONS.add:
					reason = VALIDATION_REASONS.add;
					break;
				case VALIDATION_REASONS.update:
					reason = instance.pkValue ? VALIDATION_REASONS.update : VALIDATION_REASONS.add;
					break;
				case VALIDATION_REASONS.delete:
					if (!instance.pkValue) return;
					reason = VALIDATION_REASONS.delete;
					break;
				default:
					throw new Error(`Validation Reason ${listReason} was not treated in listValidation!...`);
			}
			alls.push(validateInstance(instance, index, reason));
		});
		await Promise.all(alls);
	}
	//#endregion Validation
}
export default MControllerList;
