import API from "./UIAPI.js";
import { MFilter } from "@systypes";
import { CLASS_STATUS } from "../../shared/core/MController.js";
import { VALIDATION_REASONS } from "../../shared/core/validation/MVCore.js";
import { MRtvInstance, MRtv_ACTUAL_GET, MRtv_OBJECT_CONFIGS } from "@systypes";
//const LOADED_SYMBOL = Symbol();
export class UIController extends MRtvInstance {
	//#region RtvManagment
	get [MRtv_OBJECT_CONFIGS]() {
		return this.meta.configs;
	}
	static get [MRtv_OBJECT_CONFIGS]() {
		return this.buildMeta().configs;
	}
	//#endregion RtvManagment
	//#region PrimaryKeyManagement
	get pkValue() {
		const primaryKey = this.meta.primaryKey;
		return this[primaryKey]?.value;
	}
	//#endregion PrimaryKeyManagement
	//#region ApiHandler
	//#region Deprecated
	async get(...args) {
		return await this.constructor.get(...args);
	}
	async post(...args) {
		return await this.constructor.post(...args);
	}
	static async get(...args) {
		console.warn("method UIController.get is deprecated, please use apiGet!..");
		return await this.apiGet(...args);
	}
	static async post(...args) {
		console.warn("method UIController.post is deprecated, please use apiPost!..");
		return await this.apiPost(...args);
	}
	//#endregion Deprecated
	async apiGet(...args) {
		return await this.constructor.apiGet(...args);
	}
	async apiPost(...args) {
		return await this.constructor.apiPost(...args);
	}
	static async apiGet(aSubApi, aParams) {
		try {
			const api = this.buildMeta().api + "/" + aSubApi;
			const result = await API.get(api, aParams ? { params: aParams } : undefined);
			if (result.status === 200) {
				return result.data;
			}
			console.error(result);
			return false;
		} catch (errors) {
			console.error(errors);
			return false;
		}
	}
	static async apiPost(aSubApi, aData) {
		try {
			let config;
			if (aData instanceof FormData) {
				config = { headers: { "Content-Type": "multipart/form-data" } };
			}
			const api = this.buildMeta().api + "/" + aSubApi;
			const result = await API.post(api, aData, config);
			if (result.status === 200) {
				return result.data;
			}
			console.error(errors);
			return false;
		} catch (errors) {
			console.error(errors);
			return false;
		}
	}
	//#endregion ApiHandler;
	//#region UIMethods
	translate(...args) {
		return this.meta.translate(...args);
	}
	static translate(...args) {
		return this.meta.translate(...args);
	}
	static async prepare_cls() {
		const meta = this.buildMeta();
		const fields = meta.configs.fields;
		if (!fields) return;
		const loaders = [];
		Object.values(fields).forEach((config) => {
			const func = config.verifyInstanceClass;
			if (typeof func === "function") {
				loaders.push(func.call(config));
			}
		});
		await Promise.all(loaders);
	}
	//#endregion UIMethods
	//#region dmlOperations
	async add(aIgnoreAllWarnings, aDontThrowError) {
		const res = await this.validate(VALIDATION_REASONS.add);
		if (res && !res.validationCheck(aIgnoreAllWarnings, aDontThrowError)) {
			return res;
		}
		try {
			this[CLASS_STATUS.symbol](CLASS_STATUS.inAdd);
			const { data } = this.buildRequest("ADD");
			const result = await this.apiPost("add", data);
			if (result) this.loadRequestResponse(result);
			this[CLASS_STATUS.symbol](CLASS_STATUS.added);
			return result;
		} catch (e) {
			throw e;
		}
	}
	async update(aIgnoreAllWarnings, aDontThrowError) {
		const res = await this.validate(VALIDATION_REASONS.update);
		if (res && !res.validationCheck(aIgnoreAllWarnings, aDontThrowError)) {
			return res;
		}
		try {
			this[CLASS_STATUS.symbol](CLASS_STATUS.inUpdate);
			const { data } = this.buildRequest("UPDATE");
			const result = await this.apiPost("update", data);
			if (result) this.loadRequestResponse(result);
			this[CLASS_STATUS.symbol](CLASS_STATUS.updated);
			return result;
		} catch (e) {
			throw e;
		}
	}
	async delete(aIgnoreAllWarnings, aDontThrowError) {
		const res = await this.validate(VALIDATION_REASONS.delete);
		if (res && !res.validationCheck(aIgnoreAllWarnings, aDontThrowError)) {
			return res;
		}
		try {
			this[CLASS_STATUS.symbol](CLASS_STATUS.inDelete);
			const { data } = this.buildRequest("DELETE");
			const result = await this.apiPost("delete", data);
			this[CLASS_STATUS.symbol](CLASS_STATUS.deleted);
			return result;
		} catch (e) {
			throw e;
		}
	}
	//#endregion dmlOperations
	//#region requestManagement
	toApiRequest(aRequest) {
		const info = aRequest.blockStart(this);
		try {
			for (let [key, field] of Object.entries(this)) {
				const func = field?.toApiRequest;
				if (func) {
					aRequest.name = key;
					func.call(field, aRequest);
				} else console.info(`"${key}" dont have toApiRequest method.`);
			}
		} finally {
			aRequest.blockFinished(info);
		}
	}
	#requestBuilderAsFormData() {
		const requestResult = new FormData();
		let prefix = "";
		let suffix = "";
		const requestBuilder = {
			name: undefined,
			stack: [],
			startAt: this,
			isFormData: true,
			fullRequest: requestResult,
			append: (name, value, ...args) => requestResult.append(prefix + name + suffix, value, ...args),
			blockStart(aInstance) {
				const result = { instance: aInstance, prev_prefix: prefix, prev_suffix: suffix };
				if (this.name !== undefined) {
					result.name = this.name;
					if (!prefix) prefix = this.name;
					else {
						if (suffix) prefix = `${prefix}${this.name}${suffix}.`;
						else prefix = `${prefix}.${this.name}.`;
					}
				}
				if (!Array.isArray(aInstance)) suffix = "";
				else {
					prefix += "[";
					suffix = "]";
				}
				result.index = requestBuilder.stack.push(result) - 1;
				return result;
			},
			blockFinished(aStartInfo) {
				const info = requestBuilder.stack.pop();
				if (aStartInfo !== info) {
					throw new Error("Error in requestStack!..");
				}
				prefix = info.prev_prefix;
				suffix = info.prev_suffix;
			},
		};
		return { requestBuilder, requestResult };
	}
	#requestBuilderAsJson() {
		const requestResult = {};
		const requestBuilder = {
			name: undefined,
			stack: [],
			startAt: this,
			fullRequest: requestResult,
		};
		let current;
		requestBuilder.append = (name, value) => (current[name] = value);
		requestBuilder.blockStart = (aInstance) => {
			const result = { instance: aInstance };
			if (current === undefined) {
				if (requestBuilder.name !== undefined) {
					throw new Error("name must not be defined at top level of requestBuilding!..");
				}
				current = requestResult;
				result.prev_block = current;
			} else {
				if (!requestBuilder.name) {
					throw new Error("name missing to start new block of requestBuilding!..");
				}
				if (current[requestBuilder.name]) {
					throw new Error(`RequestBlock already has block with name ${requestBuilder.name}!..`);
				}
				result.name = requestBuilder.name;
				result.prev_block = current;
				const block_value = Array.isArray(aInstance) ? [] : {};
				current[requestBuilder.name] = block_value;
				current = block_value;
				requestBuilder.name = null;
			}
			result.index = requestBuilder.stack.push(result) - 1;
			return result;
		};
		requestBuilder.blockFinished = (aStartInfo) => {
			const info = requestBuilder.stack.pop();
			if (aStartInfo !== info) {
				throw new Error("Error in requestStack!..");
			}
			current = info.prev_block;
		};
		return { requestBuilder, requestResult };
	}
	requestConfig(aConfig, ...args) {
		return aConfig;
	}
	buildRequest(...args) {
		const reqType = args[0];
		const config = this.requestConfig({ useFormData: this.useFormData }, ...args);
		if (typeof config.beforeBuild === "function") config.beforeBuild.call(this, config, ...args);
		if (typeof this.beforeBuildRequest === "function") this.beforeBuildRequest(config, ...args);
		const { requestBuilder, requestResult: data } = config.useFormData
			? this.#requestBuilderAsFormData(...args)
			: this.#requestBuilderAsJson(...args);
		if (reqType === "DELETE") {
			const info = requestBuilder.blockStart();
			requestBuilder.append("ID", this.pkValue);
			requestBuilder.blockFinished(info);
		} else this.toApiRequest(requestBuilder);
		if (typeof config.beforePost === "function") config.beforePost.call(this, data, config, ...args);
		if (typeof this.beforePost === "function") this.beforePost(data, config, ...args);
		return { data, config };
	}
	//#endregion requestManagement
	//#region responseManagement
	loadRequestResponse(aData) {
		if (!aData) return false;
		const params = { receivedAt: this, byResponse: true, byRequestResponse: true };
		const loadByObject = (aResponseData, aWorkAt, aPath) => {
			for (let [key, value] of Object.entries(aResponseData)) {
				if (value === null && Array.isArray(aWorkAt)) {
					continue;
				}
				const field = aWorkAt[key];
				const path = aPath ? `${aPath}.${key}` : key;
				if (value && typeof value === "object") {
					if (!field) throw new Error(`${path} is not a field.`);
					const instance = field[MRtv_ACTUAL_GET] || field;
					if (!instance) throw new Error(`${path} value is not instance.`);
					loadByObject(value, instance, path);
				} else {
					const func = field?.fromApiResponse;
					if (typeof func !== "function") {
						throw new Error(`${path} dont have fromApiResponse method.`);
					}
					try {
						params.fieldPath = path;
						func.call(field, value, params);
					} finally {
						delete params.fieldPath;
					}
				}
			}
		};
		loadByObject(aData, this, "");
		return this;
	}
	async loadFromResponse(aValues, aInfo) {
		const responseInfo = { instance: this, byResponse: true, response: aValues };
		if (!aInfo) {
			responseInfo.receivedAt = this;
			responseInfo.loadStack = [{ instance: this }];
		} else {
			responseInfo.receivedAt = aInfo.receivedAt;
			responseInfo.loadStack = aInfo.loadStack;
			var meInStack = { instance: this, name: aInfo.name, resValue: aInfo.resValue };
			responseInfo.loadStack.push(meInStack);
		}
		for (let [name, field] of Object.entries(this)) {
			const func = field.fromApiResponse;
			if (func) {
				const value = aValues[name];
				responseInfo.name = name;
				responseInfo.resValue = value;
				await func.call(field, value, responseInfo);
			} else {
				console.error(`can not load response for field '${name}'!..`);
			}
		}
		if (aInfo) {
			if (meInStack !== aInfo.loadStack.pop()) {
				throw new Error("Error in loadStack!..");
			}
		}
	}
	static async createFromResponse(aData, ...args) {
		const result = new this();
		try {
			result[CLASS_STATUS.symbol](CLASS_STATUS.inLoad);
			await result.loadFromResponse(aData, ...args);
			result[CLASS_STATUS.symbol](CLASS_STATUS.loaded);
			return result;
		} catch (e) {
			throw e;
		}
	}
	static async createListFromResponse(aData) {
		const result = [];
		if (!aData) return result;
		const loaders = [];
		aData.forEach((row) => {
			const loader = this.createFromResponse(row);
			const index = loaders.push(loader) - 1;
			loader.then((instance) => (result[index] = instance));
		});
		await Promise.all(loaders);
		return result;
	}
	//#endregion responseManagement
	//#region Loading
	//#region SingleLoading
	async verifyLoaded(aStrategy = "allWithRelations") {
		const id = this.ID.value;
		//defere performing to decress errors.
		//if (this[LOADED_SYMBOL] === id) return true;
		const result = await this.apiGet("get", { ID: id, strategy: aStrategy });
		if (!result) {
			throw new Error(`instance with id ${id} was not found!.`);
		}
		try {
			this[CLASS_STATUS.symbol](CLASS_STATUS.inLoad);
			await this.loadFromResponse(result);
			//this[LOADED_SYMBOL] = id;
			this[CLASS_STATUS.symbol](CLASS_STATUS.loaded);
			return result;
		} catch (e) {
			throw e;
		}
	}
	static async loadByPK(aPrimarKey, aStrategy = "allWithRelations") {
		const result = await this.apiGet("get", { ID: aPrimarKey, strategy: aStrategy });
		if (!result) return;
		return await this.createFromResponse(result);
	}
	//#endregion SingleLoading
	//#region ArrayLoading
	static filterToApi(aFilter) {
		if (typeof aFilter === "string") return aFilter;
		const configs = this.buildMeta().configs;
		const filedGetter = (name) => configs.fields?.[name] && name;
		const filter = new MFilter(filedGetter, aFilter);
		return filter.asString();
	}
	static async loadByFilter(aStrategy = "default", aFilter) {
		const params = { strategy: aStrategy };
		if (aFilter) params.filter = this.filterToApi(aFilter);
		const result = await this.apiGet("getAll", params);
		if (!result) return;
		return await this.createListFromResponse(result);
	}
	//#endregion ArrayLoading
	//#endregion Loading
	//#region ApiMethods
	static async getSlice(aParams = {}) {
		aParams.limit = aParams.limit || 10;
		aParams.offset = aParams.offset || 0;
		return await this.createListFromResponse(await this.apiGet("getSlice", aParams));
	}
	static async getAll(aParams = { strategy: "default" }) {
		return await this.createListFromResponse(await this.apiGet("getAll", aParams));
	}
	static async search(aParams = {}) {
		return await this.createListFromResponse(await this.apiGet("search", aParams));
	}
	static async isExists(aFilter, aStrategy) {
		const params = { filter: this.filterToApi(aFilter) };
		if (aStrategy) params.strategy = aStrategy;
		return await this.apiGet("isExists", params);
	}
	static async lastValueOf(aField, aFilter) {
		const params = { field: aField };
		if (aFilter) params.filter = this.filterToApi(aFilter);
		return await this.apiGet("lastValueOf", params);
	}
	//#endregion ApiMethods
	//#region UIConfiguration
	get useFormData() {
		return this.constructor.useFormData;
	}
	static get useFormData() {
		return false;
	}
	//#endregion UIConfiguration

	//#region tempUntilFinishUpgrade
	on(...args) {
		console.warn("method on is deprecated, please use onChangedAdd!..");
		return this.onChangedAdd(...args);
	}
	off(...args) {
		console.warn("method off is deprecated, please use onChangedRemove!..");
		return this.onChangedRemove(...args);
	}
	static mapVars(aCallback) {
		console.warn("method mapVars is deprecated!..");
		const fields = this.buildMeta().configs.fields;
		if (!fields) return;
		for (let [name, config] of Object.entries(fields)) {
			aCallback(name, config);
		}
	}
	//#endregion tempUntilFinishUpgrade
}

export default UIController;
