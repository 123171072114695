export default class UIApp {
	initializeItem(aItem) {
		if (aItem.title && typeof aItem.title === "string") {
			aItem.titleResource = aItem.title;
			aItem.title = (aTrans) => this.translate(aTrans, aItem.titleResource);
		}
	}
	translate(aTrans, aName) {
		const { exists: isExists, t } = aTrans.i18n;
		const resName = `${this.appName}.${aName}`;
		if (isExists(resName)) {
			return t(resName);
		}
		return t(aName);
	}
}
