import React from "react";
import UIApplication from "@core";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals.js";

UIApplication.registerApps([
	// import("./apps/learning/Lms.Ui.Main.js"),
	import("./apps/security/Sec.ui.Main.js"),
	import("./apps/people/Ppl.ui.Main.js"),
	import("./apps/accounting/Acc.ui.Main.js"),
	import("./apps/system/Sys.ui.Main.js"),
	import("./apps/stocking/Stk.ui.Main.js"),
	import("./apps/humans/Hr.ui.Main.js"),
]).then(async () => {
	await UIApplication.finalize();
	const App = (await import("./App.js")).default;
	const root = ReactDOM.createRoot(document.getElementById("root"));
	root.render(
		<React.StrictMode>
			<App />
		</React.StrictMode>
	);
});

reportWebVitals();

