export function isClass(aObj) {
	const isCtorClass = aObj.constructor && aObj.constructor.toString().substring(0, 5) === "class";
	if (aObj.prototype === undefined) {
		return isCtorClass;
	}
	const isPrototypeCtorClass =
		aObj.prototype.constructor &&
		aObj.prototype.constructor.toString &&
		aObj.prototype.constructor.toString().substring(0, 5) === "class";
	return isCtorClass || isPrototypeCtorClass;
}

export function isFunction(funcOrClass) {
	const propertyNames = Object.getOwnPropertyNames(funcOrClass);
	return !propertyNames.includes("prototype") || propertyNames.includes("arguments");
}

export function isObject(obj) {
	return Object.prototype.toString.call(obj) === "[object Object]";
}

export function classToInvokable(aClass) {
	return new Proxy(aClass, {
		apply(ATarget, AThisArg, AArgs) {
			return new aClass(...AArgs);
		},
		construct(ATarget, AArgs) {
			return new aClass(...AArgs);
		},
	});
}

export function objectToString(aObject, aSpace = "\t") {
	function objectToString_Int(a1Object, a1Space, a1SingleSapce) {
		let res = "";
		for (let [key, value] of Object.entries(a1Object)) {
			if (!value) {
				continue;
			}
			let str = undefined;
			if (typeof value === "object") {
				str = objectToString_Int(value, a1SingleSapce + a1Space, a1SingleSapce);
				if (str) {
					str = `{\n${str}\n${a1Space}}`;
				}
			} else if (typeof value === "string") {
				str = `"${value}"`;
			} else {
				str = value.toString();
			}
			if (str) {
				res += `${a1Space}${key}: ${str},\n`;
			}
		}
		if (res === "") {
			return undefined;
		}
		return res.substring(0, res.length - 1);
	}

	if (typeof aSpace === "number") {
		aSpace = " ".repeat(aSpace);
	}
	let res = objectToString_Int(aObject, aSpace, aSpace);
	if (res) {
		return `{\n${res}\n};`;
	}
	return "";
}

export function deepFreeze(aObject) {
	Object.keys(aObject).forEach((property) => {
		if (typeof aObject[property] === "object" && !Object.isFrozen(aObject[property])) {
			deepFreeze(aObject[property]);
		}
	});
	return Object.freeze(aObject);
}
