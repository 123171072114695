import * as MRtvEvents from "./MRtv.Events.js";
export * from "./MRtv.Events.js";
const MRtv_EVENTS_TYPES_VALUE = MRtvEvents.MRtv_EVENTS_TYPES_VALUE;
const MRtv_EVENTS_TYPES_STATUS = "#status";
export const MRtv_EVENTS_TYPES_VALUES = "#values";

export class MRtvItem {
	//#region Creator
	#root;
	#path;
	constructor(aRoot, aPath) {
		this.#root = aRoot;
		this.#path = aPath;
	}
	static create_cls(aRoot, aPath, aError) {
		const applier = (aPath) => {
			if (!aRoot || !aPath || !MRtvEvents.MRtvUtils.isUseablePath(aPath, aError)) {
				if (aError) {
					throw new Error("Root&Path is required to create item!.");
				}
				return undefined;
			}
			const config = MRtvEvents.MRtvUtils.getPathConfig(aPath);
			const cls = config.itemClass || aPath.rootConfig.defaultItemClass || this;
			const result = new cls(aRoot, aPath);
			Object.freeze(result);
			return result;
		};
		if (aPath instanceof Promise) {
			return new Promise((resolve, reject) => aPath.then((p) => resolve(applier(p))).catch(reject));
		}
		return applier(aPath);
	}
	static nestedOf_cls(aItem, aName, aError) {
		const root = aItem.#root;
		const srcPath = aItem.#path;
		if (!root || !srcPath) {
			throw new Error("invalid source item path!.");
		}
		const keys = srcPath.keys;
		let path = "";
		for (let i = 0; i < keys.length - 1; i++) {
			path += keys[i] + ".";
		}
		path += aName;
		const obj = MRtvEvents.MRtvUtils.parsePath(srcPath.rootConfig, path, aError);
		return this.create_cls(root, obj);
	}
	//#endregion Creator
	//#region Information
	get keys() {
		return this.#path.keys;
	}
	get pathName() {
		let result = "";
		this.#path.keys.forEach((key) => (result += key + "."));
		return result.slice(0, -1);
	}
	get fullPath() {
		let result = "";
		MRtvEvents.MRtvUtils.enumPaths(this.#path, (key) => (result += key + "."));
		return result.slice(0, -1);
	}
	get pathObject() {
		return this.#path;
	}
	enumPaths(aCallback, ...args) {
		return MRtvEvents.MRtvUtils.enumPaths(this.#path, aCallback, ...args);
	}
	//#region FieldsInformation
	get finalField() {
		let holder = this.#root.holder ?? null;
		if (!holder) {
			return null;
		}
		let result;
		MRtvEvents.MRtvUtils.enumPaths(this.#path, (aKey) => {
			if (result && typeof result === "object") {
				holder = result[MRtvEvents.MRtv_ACTUAL_GET] || result;
			}
			result = holder[aKey];
			return result;
		});
		return typeof result === "object" ? result : undefined;
	}
	get finalConfig() {
		return MRtvEvents.MRtvUtils.getPathConfig(this.#path);
	}
	get field() {
		console.warn("item.field is deprecated, please use finalField.");
		return this.finalField;
	}
	//#endregion FieldsInformation
	//#endregion Information
	//#region value
	get value() {
		let result = this.#root.holder ?? null;
		let useConfig = this.#path.rootConfig;
		if (result) {
			MRtvEvents.MRtvUtils.enumPaths(this.#path, (aKey, aConfig) => {
				result = MRtvEvents.MRtvUtils.getValueOf(useConfig, result, aKey) ?? null;
				useConfig = aConfig;
				return result;
			});
		}
		return result;
	}
	valueOrCreate(...args) {
		const field = this.finalField;
		const getter = field?.valueOrCreate;
		if (typeof getter === "function") {
			return getter.call(field, ...args);
		} else throw new Error(`can not get valueOrCreate for item '${this.fullPath}'!.`);
	}
	set value(aValue) {
		return this.setValue(aValue);
	}
	setValue(aValue, ...args) {
		let inst, inst_config, prev_key, prev_config;
		MRtvEvents.MRtvUtils.enumPaths(this.#path, (aKey, aConfig) => {
			if (prev_key) {
				inst = MRtvEvents.MRtvUtils.getValueOf(inst_config, inst, prev_key);
				inst_config = prev_config;
			} else {
				inst = this.#root.holder;
				inst_config = this.#path.rootConfig;
			}
			prev_key = aKey;
			prev_config = aConfig;
			return inst;
		});
		if (!inst || !prev_key) {
			throw Error(`item ${this.path} is Dummy!...`);
		}
		return MRtvEvents.MRtvUtils.setValueOf(inst_config, inst, prev_key, aValue, ...args);
	}
	//#endregion value
	//#region Status
	get status() {
		return this.finalField?.status;
	}
	getDefinedState(aName, aDefault) {
		const field = this.finalField;
		return field?.[aName] ?? aDefault;
	}
	setDefinedState(aName, aValue, aAccepted) {
		const field = this.finalField;
		if (field) return (field[aName] = aValue);
		else if (aValue !== aAccepted) {
			throw new Error(`Can Not Change State[${aName}] For Item '${this.fullPath}'!.`);
		}
		return true;
	}
	//#endregion Status
	//#region Events
	eventsModification(aAdding, aType, aCallback, atAll, aCallby, aPayload) {
		const eventFunc = aAdding === true ? "eventAdding" : "eventRemoving";
		let path;
		if (!atAll) path = this.#path;
		else {
			path = MRtvEvents.MRtvUtils.parsePath(this.#root.config, this.fullPath + `.${atAll}`);
			MRtvEvents.MRtvUtils.isEventablePath(path, true);
		}
		this.#root[eventFunc](aType, path, aCallback, aCallby, aPayload);
	}
	onChangedAdd(aCallback, atAll, aCallby, aPayload) {
		return this.eventsModification(true, MRtv_EVENTS_TYPES_VALUE, ...arguments);
	}
	onStatusAdd(aCallback, atAll, aCallby, aPayload) {
		return this.eventsModification(true, MRtv_EVENTS_TYPES_STATUS, ...arguments);
	}
	onChangedRemove(aCallback, atAll) {
		return this.eventsModification(false, MRtv_EVENTS_TYPES_VALUE, ...arguments);
	}
	onStatusRemove(aCallback, atAll) {
		return this.eventsModification(false, MRtv_EVENTS_TYPES_STATUS, ...arguments);
	}
	//#endregion Events
}

export class MRtvField {
	//#region Constructor
	constructor(aFields, aName, aConfig, aMngmtclass, aSmallName) {
		if (!aFields) {
			throw Error("Owner fields is required to create new field!..");
		}
		if (!aName) {
			throw Error("fieldName is required to create new field!..");
		}
		this.#name = aName;
		this.#class = aMngmtclass;
		this.#config = aConfig;
		this.#fields = aFields;
	}
	static create_cls(aFields, aName, aConfig, aMngmtclass, aSmallName) {
		const result = new this(aFields, aName, aConfig, aMngmtclass, aSmallName);
		//Object.freeze(result); used by session in dmlOperations;
		return result;
	}
	//#endregion Constructor
	//#region information
	#name;
	#class;
	#config;
	#fields;
	get name() {
		return this.#name;
	}
	get config() {
		return this.#config;
	}
	get fields() {
		return this.#fields;
	}
	get instance() {
		return this.#fields.instance;
	}
	get mngmtclass() {
		return this.#class;
	}
	//#endregion information
	//#region verification
	verifyInstance() {
		const instance = this.instance;
		if (!instance || !(instance instanceof MRtvInstance)) {
			throw Error(`Field ${this.name} is Dummy!.`);
		}
		return instance;
	}
	//#endregion verification
	//#region ValueManagement
	#value;
	get value() {
		return this.#value;
	}
	valueOrCreate(aParams) {
		if (!this.#value && typeof this.doCreate === "function") {
			const new_value = this.doCreate(aParams);
			if (new_value) {
				const setter = (value) => {
					const new_params = !aParams;
					if (new_params) aParams = { autoCreated: true };
					else aParams.autoCreated = true;
					this.setValue(value, aParams);
					if (new_params) aParams = undefined;
					else delete aParams.autoCreated;
				};
				if (new_value instanceof Promise) {
					return new Promise((resolve, reject) =>
						new_value
							.then((value) => {
								setter(value);
								resolve(value);
							})
							.catch(reject)
					);
				} else setter(new_value);
			}
		}
		return this.#value;
	}
	set value(aValue) {
		return this.setValue(aValue);
	}
	internalSetValue(aValue) {
		return (this.#value = aValue);
	}
	setValue(aValue, aParams) {
		const instance = this.verifyInstance();
		const root = instance.root;
		const old = this.#value;
		if (Object.is(old, aValue)) {
			return false;
		}
		const old_value = MRtvEvents.MRtvUtils.beforeChangeValue(old);
		this.#value = aValue;
		root.valueChanged(this.#name, this.#config, aValue, old_value, aParams);
		return { instance, old_value, new_value: aValue };
	}
	get [MRtvEvents.MRtv_ACTUAL_GET]() {
		return this.value;
	}
	[MRtvEvents.MRtv_ACTUAL_SET](...args) {
		return this.setValue(...args);
	}
	//#endregion ValueManagement
	//#region FilterManagement
	asFilterValue() {
		return this.#value;
	}
	//#endregion FilterManagement
	//#region StatusManagement
	#status = {};
	get status() {
		return this.#status;
	}
	set status(aStatus) {
		return this.setStatus(aStatus);
	}
	setStatus(aStatus, ...args) {
		const root = this.verifyInstance().root;
		if (aStatus && typeof aStatus !== "object") {
			throw Error(`newStatus for field ${this.#name} must be object!..`);
		}
		let diffCount = 0;
		const newStatus = {};
		const difference = {};
		if (!aStatus) {
			for (let [key, value] of Object.entries(this.#status)) {
				difference[key] = { new: undefined, old: value };
				diffCount++;
			}
		} else {
			for (let [key, value] of Object.entries(this.#status)) {
				let inNew = aStatus[key];
				if (Object.is(value, inNew)) {
					newStatus[key] = value;
				} else {
					if ((inNew ?? undefined) !== undefined) {
						newStatus[key] = inNew;
					}
					difference[key] = { new: inNew, old: value };
					diffCount++;
				}
			}
			for (let [key, value] of Object.entries(aStatus)) {
				if (!Object.hasOwn(this.#status, key)) {
					if ((value ?? undefined) !== undefined) {
						newStatus[key] = value;
						difference[key] = { new: value, old: undefined };
						diffCount++;
					}
				}
			}
		}
		if (diffCount === 0) {
			return false;
		}
		this.#status = newStatus;
		root.eventEmit(MRtv_EVENTS_TYPES_STATUS, this.#name, this.#config, this.#status, difference, ...args);
		return difference;
	}
	includeStatus(aStatus, ...args) {
		if (aStatus) {
			if (Array.isArray(aStatus)) {
				const status = { ...this.#status };
				for (let name of array) {
					if (!isObjectKey(name)) {
						throw Error("includeStatus param must be object or array of keys!..");
					}
					if (!status[name]) {
						status[name] = true;
					}
				}
				return this.setStatus(status, ...args);
			}
			if (typeof aStatus === "object") {
				const status = { ...this.#status };
				for (let [name, value] of Object.entries(aStatus)) {
					if (value === undefined) delete status[name];
					else status[name] = value;
				}
				return this.setStatus(status, ...args);
			}
		}
		throw Error("includeStatus param must be object or array of keys!..");
	}
	excludeStatus(aStatus, ...args) {
		if (!aStatus || !Array.isArray(aStatus)) {
			throw Error("excludeStatus param must be array of keys!..");
		}
		const status = { ...this.#status };
		for (let name of aStatus) {
			if (!isObjectKey(name)) {
				throw Error("excludeStatus param must be array of keys!..");
			}
			delete status[name];
		}
		return this.setStatus(status, ...args);
	}
	setState(aName, aValue, ...args) {
		const root = this.verifyInstance().root;
		const old = this.#status[aName];
		if (Object.is(old, aValue)) {
			return false;
		}
		const difference = { [aName]: { new: aValue, old } };
		if ((aValue ?? undefined) === undefined) {
			delete this.#status[aName];
		} else {
			this.#status[aName] = aValue;
		}
		root.eventEmit(MRtv_EVENTS_TYPES_STATUS, this.#name, this.#config, this.#status, difference, ...args);
		return difference;
	}
	setStateResetOpposite(aName, aOpposite, aValue, ...args) {
		const root = this.verifyInstance().root;
		const old = this.#status[aName];
		const opposite_old = this.#status[aOpposite] ?? !old;
		const opposite_new = !aValue;
		let v_equal = Object.is(old, aValue);
		let o_equal = Object.is(opposite_old, opposite_new);
		if (v_equal && o_equal) {
			return false;
		}
		const difference = {};
		if (!v_equal) {
			difference[aName] = { new: aValue, old };
		}
		if (!o_equal) {
			difference[aOpposite] = { new: opposite_new, old: opposite_old };
		}
		if ((aValue ?? undefined) === undefined) {
			delete this.#status[aName];
			this.#status[aOpposite] = opposite_new;
		} else {
			this.#status[aName] = aValue;
			delete this.#status[aOpposite];
		}
		root.eventEmit(MRtv_EVENTS_TYPES_STATUS, this.#name, this.#config, this.#status, difference, ...args);
		return difference;
	}
	defineState(aName, aOpposite) {
		if (!aOpposite) {
			Object.defineProperty(this, aName, { get: () => this.#status[aName], set: this.setState.bind(this, aName) });
		} else {
			const getter = (a1Name, a1Opposite) => this.#status[a1Name] ?? !(this.#status[a1Opposite] ?? a1Name !== aName);
			Object.defineProperty(this, aName, { get: getter.bind(this, aName, aOpposite), set: this.setStateResetOpposite.bind(this, aName, aOpposite) }); /* prettier-ignore */
			Object.defineProperty(this, aOpposite, { get: getter.bind(this, aOpposite, aName), set: this.setStateResetOpposite.bind(this, aOpposite, aName) }); /* prettier-ignore */
		}
	}
	//#endregion StatusManagement
}

export class MRtvFields {
	//#region Creators
	constructor(aInstance) {
		if (!aInstance || !(aInstance instanceof MRtvInstance)) {
			throw Error("MRtvFields Owner must be instanceOf MRtvInstance!..");
		}
		this.#instance = aInstance;
	}
	static createFor_cls(aInstance, aConfig) {
		const result = new this(aInstance);
		this.initialize_cls(result, aConfig);
		Object.freeze(result);
		return result;
	}
	static initialize_cls(aFields, aConfig) {
		const fields = aConfig?.fields;
		if (fields) {
			for (let [name, value] of Object.entries(fields)) {
				aFields.addField(name, value, aConfig.defaultFieldClass);
			}
		}
	}
	//#endregion Creators
	//#region Fields
	#fields = {};
	#fieldsAsProperties = {};
	addField(aName, aConfig, aFieldClass) {
		let mngmtclass;
		if (aConfig.prototype instanceof MRtvInstance || aConfig.prototype instanceof MRtvList) {
			mngmtclass = aConfig;
			aConfig = aConfig[MRtvEvents.MRtv_OBJECT_CONFIGS];
		}
		if (aConfig.specificValue) {
			this.addProperty(aName, aConfig.specificValue);
			return true;
		}
		const cls = aConfig.fieldClass || aFieldClass || MRtvField;
		const smallName = aConfig.smallName || aName[0].toLowerCase() + aName.substring(1);
		const result = cls.create_cls(this, aName, aConfig, mngmtclass, smallName !== aName ? smallName : undefined);
		this.#fields[aName] = result;
		this.addProperty(aName, { value: result, enumerable: !aConfig.innumerable });
		if (smallName !== aName) {
			this.addProperty(smallName, {
				get: () => result.value,
				set: (...args) => result.setValue(...args),
				enumerable: false,
			});
		} else {
			console.warn(`Can not add valueUsing for field ${aName}!..`);
		}
		return result;
	}
	addProperty(aName, aDescriptor) {
		if (this.#fieldsAsProperties[aName]) {
			throw Error(`Property ${aName} was already defined!.`);
		}
		this.#fieldsAsProperties[aName] = aDescriptor;
	}
	get fields() {
		return this.#fields;
	}
	get asProperties() {
		return this.#fieldsAsProperties;
	}
	//#endregion Fields
	//#region Information
	#instance;
	get instance() {
		return this.#instance;
	}
	//#endregion Information
}

function MRtvObject(aBase) {
	class RtvObject extends aBase {
		//#region Creators
		constructor(aConfig) {
			super();
			const fieldsConfigs = aConfig || this.constructor[MRtvEvents.MRtv_OBJECT_CONFIGS];
			if (!fieldsConfigs) {
				throw Error("can not create RtvObject without config!.");
			}
			new MRtvEvents.MRtvObserver(this, fieldsConfigs);
			this.initialize(); //Zafer temp until find another way;
		}
		initialize() {
			const original = this[MRtvEvents.MRtv_GET_PROXY_TARGET] || this;
			original.#objectEvents(true);
			//Object.freeze(this);
		}
		static create_cls(...args) {
			const result = new this(...args);
			//result.initialize(...args);
			return result;
		}
		//#endregion Creators
		//#region Information
		get root() {
			return this[MRtvEvents.MRtv_ROOT_OBSERVER];
		}
		get config() {
			return this[MRtvEvents.MRtv_ROOT_OBSERVER].config;
		}
		get original() {
			return this[MRtvEvents.MRtv_GET_PROXY_TARGET] || this;
		}
		isOwnerValue(aValue) {
			if (typeof aValue === "string" || aValue instanceof String) {
				aValue = this[aValue];
			}
			if (!aValue || typeof aValue !== "object") return false;
			const valueRoot = aValue[MRtvEvents.MRtv_ROOT_OBSERVER];
			if (!valueRoot) return false;
			return valueRoot.isAnOwner(this);
		}
		allValuesAsString(aPrefix = "", aIncludeNulls) {
			let msg = "";
			for (let [name, value] of Object.entries(this)) {
				if (value && typeof value === "object") {
					const v = "value" in value ? value.value : value;
					if (v && v.allValuesAsString) {
						if (!this.isOwnerValue(v)) {
							msg += v.allValuesAsString(`${aPrefix}${name}.`, aIncludeNulls);
						}
					} else if ((v ?? undefined) !== undefined || (aIncludeNulls ?? true) === true) {
						msg += `${aPrefix}${name}: ${v}\n`;
					}
				} else if ((value ?? undefined) !== undefined || (aIncludeNulls ?? true) === true) {
					msg += `${aPrefix}${name}: ${value}\n`;
				}
			}
			return msg;
		}
		//#endregion Information
		//#region PathManagement
		itemOf(aPath) {
			const root = this.root;
			if (!root) {
				throw Error("Observer required!.");
			}
			return MRtvItem.create_cls(root, MRtvEvents.MRtvUtils.parsePath(root.config, aPath), true);
		}
		tryGetItemByPath(aPath) {
			const root = this.root;
			if (!root) {
				return undefined;
			}
			const result = MRtvEvents.MRtvUtils.parsePath(root.config, aPath);
			return MRtvItem.create_cls(root, result, false);
		}
		valueOf(aPath) {
			if (aPath in this) {
				const value = this[aPath];
				if (value && typeof value === "object" && MRtvEvents.MRtv_ACTUAL_GET in value) {
					return value[MRtvEvents.MRtv_ACTUAL_GET];
				}
				return value;
			}
			const item = this.itemOf(aPath);
			if (item instanceof Promise) {
				return new Promise((resolve, reject) => item.then((act) => resolve(act.value)).catch(reject));
			}
			return item.value;
		}
		setValueOf(aPath, aValue, aParams) {
			if (aPath in this) {
				const current = this[aPath];
				if (current && typeof current === "object" && MRtvEvents.MRtv_ACTUAL_SET in current) {
					return current[MRtvEvents.MRtv_ACTUAL_SET](aValue, aParams);
				}
				return (this[aPath] = aValue);
			}
			const item = this.itemOf(aPath);
			if (item instanceof Promise) {
				return new Promise((resolve, reject) =>
					item.then((act) => resolve(act.setValue(aValue, aParams))).catch(reject)
				);
			}
			return item.setValue(aValue, aParams);
		}
		//#endregion PathManagement
		//#region EventsManagement
		#objectEvents(aAdding) {
			const loc_modification = (aType, aPaths, aCallback, aCallby, aPayload) => {
				aCallby = aCallby || this;
				if (Array.isArray(aPaths)) {
					aPaths.forEach((path) => this.eventsModification(aAdding, aType, path, aCallback, aCallby, aPayload));
				} else {
					this.eventsModification(aAdding, aType, aPaths, aCallback, aCallby, aPayload);
				}
			};
			this.enumEvents({
				onChanged: loc_modification.bind(this, MRtv_EVENTS_TYPES_VALUE),
				onStatus: loc_modification.bind(this, MRtv_EVENTS_TYPES_STATUS),
				onValues: loc_modification.bind(this, MRtv_EVENTS_TYPES_VALUES),
			});
		}
		enumEvents({ onChanged, onStatus, onValues }) {}
		eventsModification(aAdding, aType, aPath, aCallback, aCallby, aPayload) {
			const eventFunc = aAdding === true ? "eventAdd" : "eventRemove";
			return this.root[eventFunc](aType, aPath, aCallback, aCallby, aPayload);
		}
		onChangedAdd(...args) {
			this.eventsModification(true, MRtv_EVENTS_TYPES_VALUE, ...args);
		}
		onStatusAdd(...args) {
			this.eventsModification(true, MRtv_EVENTS_TYPES_STATUS, ...args);
		}
		onChangedRemove(...args) {
			this.eventsModification(false, MRtv_EVENTS_TYPES_VALUE, ...args);
		}
		onStatusRemove(...args) {
			this.eventsModification(false, MRtv_EVENTS_TYPES_STATUS, ...args);
		}
		//#endregion EventsManagement
		//#region ChangesObserving
		#changes;
		get changes() {
			return this.#changes;
		}
		#changesRecording(aNew, aOld, aParams) {
			if (aParams.listener?.location) return;
			const n = typeof aNew === "object" ? aNew?.[MRtvEvents.MRtv_ACTUAL_GET] || aNew : aNew;
			if (n && typeof n === "object") return;
			const { path } = aParams;
			let value = this.#changes[path];
			if (value) {
				if (!Object.is(value.old, aNew)) value.last = n;
				else delete this.#changes[path];
			} else {
				const o = typeof aOld === "object" ? aOld?.[MRtvEvents.MRtv_ACTUAL_GET] || aOld : aOld;
				if (!o || typeof o !== "object") this.#changes[path] = { old: o, last: n };
			}
		}
		resetChanges(aValue) {
			const original = this[MRtvEvents.MRtv_GET_PROXY_TARGET] || this;
			const changes = original.#changes;
			if (aValue === false) {
				original.#changes = undefined;
			} else if (aValue === undefined || aValue === true) {
				original.#changes = {};
			} else if (aValue && typeof aValue === "object") {
				original.#changes = {};
				for (let [key, value] of Object.entries(aValue)) {
					original.#changes[key] = { old: null, last: value };
				}
			} else {
				throw Error("isChangesObserving param must be boolean or object!.");
			}
			if (!!original.#changes !== !!changes) {
				if (original.#changes) original.onChangedAdd("**", original.#changesRecording, original);
				else original.onChangedRemove("**", original.#changesRecording);
			}
			return changes;
		}
		get observeChanges() {
			return !!this.#changes;
		}
		set observeChanges(aValue) {
			return this.resetChanges(aValue);
		}
		//#endregion ChangesObserving
	}

	return RtvObject;
}
export class MRtvInstance extends MRtvObject(Object) {
	constructor(...args) {
		super(...args);
		const config = this.root.config;
		const fieldsClass = config.fieldsClass || MRtvFields;
		const fields = fieldsClass.createFor_cls(this, config);
		Object.defineProperties(this, fields.asProperties);
	}
}
export class MRtvArrayHandler extends MRtvEvents.MRtvObjectHandler {
	static get(aTarget, aProperty, aReceiver) {
		if (aProperty === MRtvEvents.MRtv_GET_PROXY_TARGET) {
			return aTarget;
		}
		if (aProperty === MRtvEvents.MRtv_VALUEOF_GETTER) {
			return aTarget[MRtvEvents.MRtv_VALUEOF_GETTER];
		}
		if (aProperty === MRtvEvents.MRtv_VALUEOF_SETTER) {
			return aTarget[MRtvEvents.MRtv_VALUEOF_SETTER];
		}
		const getter = aTarget[MRtvEvents.MRtv_VALUEOF_GETTER];
		const result = getter ? getter.call(aTarget, aProperty) : MRtvEvents.MRtv_UNTREATED;
		if (result !== MRtvEvents.MRtv_UNTREATED) return result;
		return super.get(...arguments);
	}
	static set(aTarget, aProperty, aValue, aReceiver) {
		const setter = aTarget[MRtvEvents.MRtv_VALUEOF_SETTER];
		const result = setter ? setter.call(aTarget, aProperty, aValue) : MRtvEvents.MRtv_UNTREATED;
		if (result !== MRtvEvents.MRtv_UNTREATED) return result;
		return super.set(...arguments);
	}
}
export class MRtvList extends MRtvObject(Array) {
	//#region Constructor
	static create_cls(...args) {
		const result = super.create_cls(...args);
		return new Proxy(result, MRtvArrayHandler);
	}
	//#endregion Constructor
	//#region ChildsManagement
	[MRtvEvents.MRtv_VALUEOF_GETTER](aKey) {
		const value = this[aKey];
		if (aKey === "length") return value;
		if (aKey === "then") return undefined;
		if (typeof aKey === "symbol") return value;
		if (Number.isInteger(+aKey)) return value;
		if (typeof value === "function") return value;
		if (this.config?.fields?.[aKey]) return value;
		if (aKey in Object.getPrototypeOf(this)) return value;
		return MRtvEvents.MRtv_UNTREATED;
	}
	[MRtvEvents.MRtv_VALUEOF_SETTER](aKey, aValue, ...args) {
		if (aKey === "length") {
			this.length = aValue;
			return true;
		}
		if (typeof aKey === "symbol") {
			this[aKey] = aValue;
			return true;
		}
		let rowConfig;
		let rowClass;
		const config = this.config;
		if (!config) return MRtvEvents.MRtv_UNTREATED;
		if (typeof aKey !== "symbol" && Number.isInteger(+aKey)) {
			rowClass = config.rowsClass;
			rowConfig = config.rowsConfig || rowClass?.[MRtvEvents.MRtv_OBJECT_CONFIGS];
		} else {
			const keyConfig = config.fields?.[aKey];
			if (keyConfig) {
				rowClass = keyConfig.class || keyConfig.prototype instanceof MRtvInstance ? keyConfig : undefined;
				rowConfig = keyConfig.config || rowClass?.[MRtvEvents.MRtv_OBJECT_CONFIGS];
			}
		}
		if (rowConfig) {
			if (!aValue || aValue instanceof rowClass) {
				const result = MRtvEvents.MRtvUtils.autoValueSetter(rowConfig, this, aKey, aValue, ...args);
				const notify = this.doPropertyChanged;
				if (typeof notify === "function") {
					notify.call(this, aKey, aValue, ...args);
				}
				return result;
			} else {
				throw new Error(`ListRow [${aKey}] expected value is '${rowClass.name}' but get '${aValue}'!..`);
			}
		}
		return MRtvEvents.MRtv_UNTREATED;
	}
	//#endregion ChildsManagement
	//#region Miscellaneous
	clear(aNeedCopy) {
		const original = this[MRtvEvents.MRtv_GET_PROXY_TARGET] || this;
		const result = aNeedCopy ? [...original] : true;
		while (this.length > 0) {
			this.pop();
		}
		return result;
	}
	remove(aIndexOrValue) {
		let index;
		if (typeof aIndexOrValue === "number") {
			index = +aIndexOrValue;
		} else index = this.indexOf(aIndexOrValue);
		if (index >= 0) {
			this.splice(index, 1);
			return true;
		} else {
			return false;
		}
	}
	static get [Symbol.species]() {
		return Array;
	}
	//#endregion Miscellaneous
	//#region deprecatedMethods
	add(aInstance) {
		console.warn("method 'list.add' is deprecated, please use push method!..");
		return this.push(aInstance);
	}
	get rows() {
		console.warn("method 'list.rows' is deprecated, please use listDirect!..");
		return this;
	}
	//#endregion deprecatedMethods
}
