import MControllerList from "../../shared/core/MControllerList.js";
export class UIControllerList extends MControllerList {
	//#region ApiManagement
	toApiRequest(aRequest) {
		const info = aRequest.blockStart(this);
		try {
			for (let [key, instance] of Object.entries(this)) {
				if (instance ?? undefined !== undefined) {
					const func = instance.toApiRequest;
					if (func) {
						aRequest.name = key;
						func.call(instance, aRequest);
					} else console.info(`${key} dont have toApiRequest method.`);
				}
			}
		} finally {
			aRequest.blockFinished(info);
		}
	}
	async loadFromResponse(aValue, aResponse) {
		const original = this.original;
		const cls = await original.config.instanceClass;
		this.clear(true, aResponse);
		for (let row of aValue) {
			const instance = await cls.createFromResponse(row, aResponse);
			this.push(instance);
		}
	}
	//#endregion ApiManagement
}

export default UIControllerList;
