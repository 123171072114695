import { FilterableField } from "../../../shared/core/management/MFields.js";
import { UIField, UIItem, MRtv_OBJECT_CONFIGS, UIDependsConfig, UIFieldValues } from "../UIMeta.js";

class UIRelationItem extends UIItem {
	//#region Filter
	get filter() {
		return super.finalField.filter;
	}
	get filterDirect() {
		return super.finalField.filterDirect;
	}
	//#endregion Filter
}

class UIRelationField extends FilterableField(UIField) {
	//#region ApiManagement
	toApiRequest(aRequest) {
		if (this.isOwnerValue === true) return;
		const current = super.value;
		const value = current && typeof current === "object" ? current.pkValue : current ?? null;
		aRequest.append(this.name, value);
	}
	async fromApiResponse(aValue, aResponse) {
		let value;
		if (!aValue || typeof aValue !== "object") {
			value = aValue ?? aResponse.response[`${this.name}ID`];
		} else {
			const cls = await this.config.verifyInstanceClass();
			value = await cls.createFromResponse(aValue);
		}
		super.setValue(value, aResponse);
	}
	//#endregion ApiManagement
	setValue(aValue, aParams) {
		this.isOwnerValue = aParams?.isOwnerValue === true;
		super.setValue(...arguments);
	}
	//#region Filter
	doFilterChanged() {
		const values = super.valuesDirect;
		if (values) values.reload();
		super.doFilterChanged(...arguments);
	}
	//#endregion Filter
}

class UIRelationValues extends UIFieldValues {
	//#region Loading
	async reloadValues() {
		const config = this.config;
		const cls = await config.verifyInstanceClass();
		this.idName = cls.meta.primaryKey;
		this.titleName = config.captionIn;
		const params = { strategy: config.strategy };
		const filter = this.field.filterDirect;
		if (filter) params.filter = filter.asString();
		return await cls.getAll(params);
	}
	//#endregion Loading;
	//#region Format
	valueToOption(aTranslation, aValue) {
		if (!aValue) {
			return null;
		}
		return {
			instance: aValue,
			value: aValue[this.idName].value,
			label: aValue.valueOf(this.titleName),
		};
	}
	//#endregion Format
}

export class UIRelationConfig extends UIDependsConfig {
	//#region Constructor
	constructor(aName, aDeclaration) {
		if (!aDeclaration.relation) {
			throw Error(`can not create relation ${aName} without controller!..`);
		}
		super(aDeclaration.relation);
		this.name = aName;
		this.strategy = aDeclaration.strategy || "default";
		//relationType: aColumn.type,
		if (aDeclaration.relationCaption) {
			console.warn("prop 'relationCaption' is deprecated, please use captionIn!..");
			this.#captionIn = aDeclaration.relationCaption;
		} else {
			this.#captionIn = aDeclaration.captionIn;
		}
		this.title = aDeclaration.title || `fields.${aName}`;
		const relation = aDeclaration.relation;
		Object.defineProperty(this, "relation", {
			get() {
				console.warn("method 'RelationConfig.relation' is deprecated, please use verifyInstanceClass!..");
				return relation;
			},
		});
	}
	//#endregion Constructor
	//#region Information
	get fieldKind() {
		return "relation";
	}
	#captionIn;
	get captionIn() {
		return this.#captionIn;
	}
	get relationCaption() {
		console.warn("prop 'RelationConfig.relationCaption' is deprecated, please use captionIn!..");
		return this.#captionIn;
	}
	instanceClassLoaded(aClass) {
		if (this.#captionIn === undefined) {
			this.#captionIn = aClass.meta.defaultCaption;
		}
	}
	//#endregion Information
	//#region MRtvManagement
	get [MRtv_OBJECT_CONFIGS]() {
		const cls = this.instanceClass;
		if (cls instanceof Promise) {
			return (async () => (await cls)[MRtv_OBJECT_CONFIGS])();
		}
		return cls[MRtv_OBJECT_CONFIGS];
	}
	get fieldClass() {
		this.verifyInstanceClass();
		return UIRelationField;
	}
	get itemClass() {
		return UIRelationItem;
	}
	//#endregion MRtvManagement
	//#region FieldManagement
	get valuesClass() {
		return UIRelationValues;
	}
	//#endregion FieldManagement
}

export default UIRelationConfig;
