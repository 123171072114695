import { actualGetter } from "@systypes";
import { nextCode, isPattern, patternOfStr, createPattern } from "@coding";
let defaultPattern;
const DEFAULT_CODE = "0001";
const LISTEN_SYMBOL = Symbol();
const INITIALIZE_FUNCTION = Symbol();

const parseInput = (aField, aValue, aDefault) => {
	if (!aField || !String.isString(aField)) {
		throw new Error(`autoCode.field: expected type string but get ${aField}!..`);
	}
	if (!aValue || aValue === true) {
		const inital = aDefault || DEFAULT_CODE;
		if (!defaultPattern) defaultPattern = createPattern("0", "9", inital);
		return [aField, defaultPattern, inital];
	}
	const pattern = isPattern(aValue, true) ? aValue : patternOfStr(aValue);
	const inital = aDefault ? aDefault : String.isString(aValue) ? aValue : pattern?.inital || DEFAULT_CODE;
	if (!pattern || !inital || !String.isString(inital)) {
		throw new Error("autoCode input must be [true, func, pattern or initalString]!..");
	}
	return [aField, pattern, inital];
};

export const autoCodeByParent = (aParent, aValue) => {
	if (!aParent || !String.isString(aParent)) {
		throw new Error(`autoCodeByParent.parent: expected type string but get ${aParent}!..`);
	}
	const parentName = aParent;
	function initialize(aField, aDefault) {
		const [fieldName, pattern, inital] = parseInput(aField, aValue, aDefault);
		const listener = async function () {
			const nCode = await generator.call(this);
			this.setValueOf(fieldName, nCode, { autoCoding: true });
		};
		async function generator() {
			const field = this[fieldName];
			if (field && typeof field === "object" && !field[LISTEN_SYMBOL]) {
				field[LISTEN_SYMBOL] = true;
				this.onChangedAdd(parentName, listener, this);
			}
			const parent = actualGetter(this, parentName);
			if (parent) {
				let last = await this.lastValueOf(fieldName, [parentName, parent.pkValue]);
				let prefix = "";
				const pCode = actualGetter(parent, fieldName);
				if (!last && pCode) return pCode + inital;
				if (last && pCode && last.startsWith(pCode)) {
					last = last.substring(pCode.length);
					prefix = pCode;
				}
				return last ? prefix + nextCode(last, inital, pattern) : prefix + inital;
			} else {
				const last = await this.lastValueOf(fieldName);
				return last ? nextCode(last, inital, pattern) : inital;
			}
		}
		return generator;
	}
	initialize[INITIALIZE_FUNCTION] = true;
	return initialize;
};

const defaultAutoCode = (aValue, aField, aDefault) => {
	const [fieldName, pattern, inital] = parseInput(aField, aValue, aDefault);
	async function generate() {
		const last = await this.lastValueOf(fieldName);
		return last ? nextCode(last, inital, pattern) : inital;
	}
	return generate;
};

export default (aValue, ...args) => {
	if (aValue?.[INITIALIZE_FUNCTION] === true) {
		return aValue(...args);
	}
	if (typeof aValue === "function") {
		if (aValue === AutoCodeByParent) {
			throw new Error("AutoCodeByParent, need parent field!.");
		}
		return aValue;
	}
	return defaultAutoCode(aValue, ...args);
};
