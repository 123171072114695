let uid_Counter = 0;
const uuid = () => {
	if (uid_Counter === Number.MAX_SAFE_INTEGER) uid_Counter = 0;
	return Date.now().toString(36) + "-" + (++uid_Counter).toString(36);
};

export const counterID = (aPrefix) => (aPrefix ? `${aPrefix}.${uuid()}` : uuid());
export function uniqueID() {
	return typeof crypto?.randomUUID === "function" ? crypto.randomUUID() : uuid();
}
