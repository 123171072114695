export default (Base) => {
	class MApp extends Base {
		//#region Create&Initialize
		#initializeItem;
		constructor(...args) {
			super(...args);
			const func = this.initializeItem;
			this.#initializeItem = typeof func === "function" && func;
		}
		initialize() {
			if (!this.appName) {
				throw Error(`appName is required for App '${this.constructor.name}'!.`);
			}
			const prefix = this.appPrefix;
			if (!prefix) {
				throw Error(`appPrefix is required for App '${this.appName}'!.`);
			}
			if (!this.apiRoot) {
				throw Error(`apiRoot is required for App '${this.appName}'!.`);
			}
			for (let [name, value] of Object.entries(this.contains)) {
				const msg_Prefix = () => `Entity [${this.appName}.contains.${name}]`;
				if (!name.startsWith(prefix)) {
					throw Error(`${msg_Prefix()} must start with '${prefix}'!.`);
				}
				if (!value?.class) {
					throw Error(`${msg_Prefix()} must have class Object!.`);
				}
				if (!value.title) {
					value.title = `${name}.title`;
				}
				const subApi = name.substring(prefix.length);
				if (!value.name) {
					value.name = subApi;
				}
				if (!value.subApi) {
					value.subApi = subApi;
				} else if (value.subApi !== subApi) {
					console.info(`${msg_Prefix()} expected subApi is:'${subApi}' but get '${value.subApi}'!.`);
				}
				if (value.group) {
					if (!typeof value.group === "object") {
						throw Error(`${msg_Prefix()} group type must be object!.`);
					}
					const act_group = this[value.group.name] || value.group;
					if (!Array.isArray(act_group.items)) {
						throw Error(`${msg_Prefix()} group must have items array!.`);
					}
					act_group.items.push(value);
					delete value.group;
				}
				this.#initializeItem && this.#initializeItem(value);
			}
			this.#initializeGroups();
		}
		#initializeGroups() {
			if (!this.groups) {
				this.groups = {};
			} else if (this.#initializeItem) {
				for (let group of Object.values(this.groups)) {
					this.#initializeItem(group);
				}
			}
			const loc_AddGroup = (name) => {
				const group = this[name];
				if (group?.items.length > 0) {
					if (!this.groups[name]) {
						this.#initializeItem && this.#initializeItem(group);
						group.icon = group.icon || group.name;
						this.groups[name] = group;
					} else {
						const def_group = this.groups[name];
						def_group.icon = def_group.icon || group.icon || name;
						def_group.items = [...def_group.items, ...group.items];
					}
				} else {
					delete this[name];
				}
			};
			loc_AddGroup("Cards");
			loc_AddGroup("Operations");
			loc_AddGroup("Trees");
			loc_AddGroup("Reports");
			loc_AddGroup("Declarations");
			loc_AddGroup("Managements");
			loc_AddGroup("Tools");
			loc_AddGroup("Others");
		}
		static create_cls(...args) {
			const result = new this(...args);
			result.initialize();
			return result;
		}
		//#endregion Create&Initialize
		//#region defaultGroups
		Cards = { name: "Cards", title: "Groups.Cards", items: [] };
		Operations = { name: "Operations", title: "Groups.Operations", items: [] };
		Trees = { name: "Trees", title: "Groups.Trees", items: [] };
		Reports = { name: "Reports", title: "Groups.Reports", items: [] };
		Declarations = { name: "Declarations", title: "Groups.Declarations", items: [] };
		Managements = { name: "Managements", title: "Groups.Managements", items: [] };
		Tools = { name: "Tools", title: "Groups.Tools", items: [] };
		Others = { name: "Others", title: "Groups.Others", items: [] };
		//#endregion defaultGroups
	}
	return MApp;
};
