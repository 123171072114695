const PATTERN_SYMBOL = Symbol.for("MAlphabetPattern");
export const stringPattern = (...args) => {
	const inital = args[1];
	const length = args[0]?.length;
	let lenError = true;
	switch (args.length) {
		case 2:
			if (!inital || !String.isString(inital)) {
				throw new Error(`initalValue must be string!.`);
			}
		case 1:
			lenError = false;
		default:
			if (lenError || !length || length < 1) {
				throw new Error(`expected param stringPattern but get [${args}]!.`);
			}
	}
	const pattern = args[0];
	for (let i = 0; i < length; i++) {
		const char = pattern[i];
		const index = pattern.indexOf(char, i + 1);
		if (index > 0) {
			throw new Error(`string pattern has repeated char ${char}!.`);
		}
	}
	const start = pattern[0];
	const end = pattern[length - 1];
	const result = {
		start,
		end,
		next(aValue) {
			if (aValue?.length !== 1) return;
			const index = pattern.indexOf(aValue);
			if (index >= 0 && index < length) return pattern[index + 1];
		},
		include(aChar) {
			return aChar?.length === 1 && pattern.indexOf(aChar) >= 0;
		},
		[Symbol.iterator]() {
			let index = 0;
			return {
				next: () => {
					if (index < length) return { value: pattern[index++], done: false };
					else return { done: true };
				},
			};
		},
		[PATTERN_SYMBOL]: true,
	};
	if (inital) result.inital = inital;
	Object.freeze(result);
	return result;
};
export const createPattern = (...args) => {
	const [start, end, inital] = args;
	if (start?.length !== 1) {
		throw new Error(`pattern.start: expected type char but get ${start}!.`);
	}
	if (end?.length !== 1) {
		throw new Error(`pattern.end: expected type char but get ${end}!.`);
	}
	if (end <= start) {
		throw new Error(`pattern.end must be greaterThan pattern.end [${start}, ${end}]!.`);
	}
	if (inital && !String.isString(inital)) {
		throw new Error(`initalValue must be string!.`);
	}
	const result = {
		start,
		end,
		next(aValue) {
			if (aValue.length === 1 && aValue >= start && aValue < end) {
				return String.fromCharCode(aValue.charCodeAt(0) + 1);
			}
		},
		include(aChar) {
			return aChar?.length === 1 && aChar >= start && aChar <= end;
		},
		[Symbol.iterator]() {
			let current = start;
			return {
				next: () => {
					if (current) {
						const result = { value: current, done: false };
						current = this.next(current);
						return result;
					} else return { done: true };
				},
			};
		},
		[PATTERN_SYMBOL]: true,
	};
	if (inital) result.inital = inital;
	Object.freeze(result);
	return result;
};
const PATTERN_NUMBERS = createPattern("0", "9");
const PATTERN_ENGLISH_SMALL = createPattern("a", "z");
const PATTERN_ENGLISH_CAPITAL = createPattern("A", "Z");
const charPatterns = (() => {
	const result = {};
	for (let key of PATTERN_NUMBERS) result[key] = PATTERN_NUMBERS;
	for (let key of PATTERN_ENGLISH_SMALL) result[key] = PATTERN_ENGLISH_SMALL;
	for (let key of PATTERN_ENGLISH_CAPITAL) result[key] = PATTERN_ENGLISH_CAPITAL;
	return result;
})();

export const isPattern = (aPattern, aNoError) => {
	if (!aPattern || aPattern[PATTERN_SYMBOL] !== true) {
		if (aNoError) return;
		throw new Error(`pattern object need start value, next function!..`);
	}
	return aPattern;
};

export const alphabet = (...args) => {
	let pattern;
	switch (args.length) {
		case 0:
			pattern = PATTERN_ENGLISH_CAPITAL;
			break;
		case 1:
			switch (typeof args[0]) {
				case "object":
					pattern = isPattern(args[0], false);
					break;
				case "string":
					if (args[0].length > 1) pattern = stringPattern(args[0]);
					else {
						const org = charPatterns[args[0][0]];
						if (!org) {
							throw new Error(`one args must be start of pattern!..`);
						}
						pattern = createPattern(args[0], org.end);
					}
					break;
				default:
					throw new Error(`one args must be pattern or start char!..`);
			}
			break;
		case 2:
			if (args[1]?.length > 1) {
				if (args[0]?.length > 1) pattern = stringPattern(...args);
				else {
					const end = charPatterns[args[0]]?.end;
					pattern = createPattern(args[0], end, args[1]);
				}
			} else pattern = createPattern(...args);
			break;
		case 3:
			pattern = createPattern(...args);
			break;
		default:
			throw new Error(`alphabet: expected args (undefined,[pattern or start of pattern], [start, end])!..`);
	}
	let current = pattern.inital || pattern.start;
	const result = {
		get current() {
			return current;
		},
		get next() {
			const end = current.length - 1;
			for (let i = end; i >= 0; i--) {
				const next = pattern.next(current[i]);
				if (next) {
					current = i > 0 ? current.substring(0, i) : "";
					current += next;
					if (i < end) current += pattern.start.repeat(end - i);
					return current;
				}
			}
			current = pattern.start.repeat(current.length + 1);
			return current;
		},
	};
	Object.freeze(result);
	return result;
};

export const patternOfStr = (aString) => {
	if ((aString?.length ?? 0) <= 0) return;
	const patterns = [];
	for (let i = 0; i < aString.length; i++) {
		const pattern = charPatterns[aString[i]];
		if (!pattern) continue;
		let obj = patterns.find((e) => e.pattern === pattern);
		if (!obj) patterns.push({ pattern, count: 1 });
		else obj.count++;
	}
	return patterns.reduce((a, b) => (a.count > b.count ? a : b), patterns[0])?.pattern;
};

export const nextCode = (aValue, aInitial, aPattern) => {
	if (!aInitial || !String.isString(aInitial)) {
		if (isPattern(aInitial, true)) {
			aPattern = aInitial;
			aInitial = aPattern.inital || aPattern.start;
		} else throw new Error(`initial code, expected type string but get ${aInitial}.`);
	}
	if (!aValue) return aInitial;
	if (!String.isString(aValue)) {
		throw new Error(`current code, expected type string but get ${aValue}.`);
	}
	let suffix = "";
	let lastPattern;
	const usePattern = isPattern(aPattern, true);
	for (let i = aValue.length - 1; i >= 0; i--) {
		const char = aValue[i];
		const pattern = usePattern?.include(char) ? usePattern : charPatterns[char];
		const next = pattern?.next(char);
		if (!next) suffix = (pattern?.start || char) + suffix;
		else {
			let result = i > 0 ? aValue.substring(0, i) : "";
			result += next + suffix;
			return result;
		}
		lastPattern = pattern;
	}
	const pattern = usePattern || patternOfStr(aInitial);
	if (!pattern || !suffix) return aValue + aInitial;
	if (lastPattern) return pattern.next(pattern.start) + suffix;
	let i = 1;
	while (i < suffix.length) {
		lastPattern = charPatterns[suffix[i]];
		if (lastPattern) break;
		i++;
	}
	let next = pattern.start;
	if (lastPattern === pattern || lastPattern?.next(next) === next) next = pattern.next(next);
	return suffix.substring(0, i) + next + suffix.substring(i);
};

export default alphabet;
