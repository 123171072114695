export class MContext {
	//#region Constructor
	#parent;
	constructor(aParent) {
		if (aParent) {
			if (!(aParent instanceof MContext)) {
				throw new Error("parent context, expected value subInstace but get:" + aParent);
			}
			this.#parent = aParent;
		}
	}
	//#endregion Constructor
	//#region ValueGetter
	valueGetter(aName) {
		let work = this;
		do {
			const result = work[aName];
			if (result || result === 0) return result;
			work = work.#parent;
		} while (work);
		return undefined;
	}
	//#endregion ValueGetter
}
