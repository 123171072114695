import { actualGetter } from "@systypes";
const isNull = (aInstance, aField) => {
	const value = actualGetter(aInstance, aField);
	return value === undefined || value === null || value === "" || (Number.isNumber(value) && isNaN(value));
};
const isSingleExists = async (aValidator, aField, aInstance, aController) => {
	const value = actualGetter(aInstance, aField);
	if (!value) return false;
	const filter = aInstance.mngmtFilter(aValidator.isForUpdating);
	filter.push(["`" + aField + "`", "=", value]);
	return await aController.isExists(filter);
};
const isArrayExists = async (aValidator, aFields, aInstance, aController) => {
	let anyValue;
	const filter = aInstance.mngmtFilter(aValidator.isForUpdating);
	aFields.forEach((name) => {
		const value = actualGetter(aInstance, name);
		if (value) anyValue = true;
		filter.push(["`" + name + "`", "=", value]);
	});
	if (!anyValue) return false;
	return await aController.isExists(filter);
};

export default class MVConstraints {
	#array = [];
	get isEmpty() {
		return this.#array.length === 0;
	}
	#checkFields(aFields) {
		if (Array.isArray(aFields)) {
			const allstring = aFields.every((ele) => typeof ele === "string" || ele instanceof String);
			if (allstring) return aFields;
		} else {
			if (typeof aFields === "string") return aFields;
			else if (aFields instanceof String) return aFields;
		}
		throw new Error(`fields ${aFields} must be string or array of string!..`);
	}
	unique(aFields) {
		const fields = this.#checkFields(aFields);
		const singleUnique = async (aValidator, aInstance, aController) => {
			if (aValidator.isForDeleting || !aValidator.isValidatable(fields)) return;
			const exists = await isSingleExists(aValidator, fields, aInstance, aController);
			aValidator.error(exists, fields, "messages.errors.fieldValueExists");
		};
		const arrayUnique = async (aValidator, aInstance, aController) => {
			if (aValidator.isForDeleting || !aValidator.isAnyValidatable(fields)) return;
			const exists = await isArrayExists(aValidator, fields, aInstance, aController);
			aValidator.error(exists, fields, "messages.errors.fieldsValuesExists");
		};
		return this.#array.push(Array.isArray(fields) ? arrayUnique : singleUnique);
	}
	notNull(aFields) {
		const fields = this.#checkFields(aFields);
		const singleNotNull = (aValidator, aInstance) => {
			if (aValidator.isForDeleting || !aValidator.isValidatable(fields)) return;
			aValidator.error(isNull(aInstance, fields), fields, "messages.errors.fieldRequired");
		};
		const arrayNotNull = (aValidator, aInstance) => {
			if (aValidator.isForDeleting) return;
			fields.forEach((field) => {
				if (!aValidator.isValidatable(field)) return;
				aValidator.error(isNull(aInstance, field), field, "messages.errors.fieldRequired");
			});
		};
		return this.#array.push(Array.isArray(fields) ? arrayNotNull : singleNotNull);
	}
	anyNotNull(aFields) {
		const fields = this.#checkFields(aFields);
		const singleAnyNotNull = (aValidator, aInstance) => {
			if (aValidator.isForDeleting || !aValidator.isValidatable(fields)) return;
			aValidator.error(isNull(aInstance, fields), fields, "messages.errors.fieldRequired");
		};
		const arrayAnyNotNull = (aValidator, aInstance) => {
			if (aValidator.isForDeleting || !aValidator.isAnyValidatable(fields)) return;
			const error = fields.every((field) => isNull(aInstance, field));
			aValidator.error(error, fields, "messages.errors.anyRequired");
		};
		return this.#array.push(Array.isArray(fields) ? arrayAnyNotNull : singleAnyNotNull);
	}
	warnExists(aFields) {
		const fields = this.#checkFields(aFields);
		const singleWarnExists = async (aValidator, aInstance, aController) => {
			if (aValidator.isForDeleting || !aValidator.isValidatable(fields)) return;
			const exists = await isSingleExists(aValidator, fields, aInstance, aController);
			aValidator.warning(exists, fields, "messages.warnings.fieldValueExists");
		};
		const arrayWarnExists = async (aValidator, aInstance, aController) => {
			if (aValidator.isForDeleting || !aValidator.isAnyValidatable(fields)) return;
			const exists = await isArrayExists(aValidator, fields, aInstance, aController);
			aValidator.warning(exists, fields, "messages.warnings.fieldsValuesExists");
		};
		return this.#array.push(Array.isArray(fields) ? arrayWarnExists : singleWarnExists);
	}

	check(...args) {
		const result = [];
		this.#array.forEach((func) => {
			const execResult = func(...args);
			if (execResult instanceof Promise) {
				result.push(execResult);
			}
		});
		return result;
	}
}
//template:
// anyNotNull(aFields) {
//   const fields = this.#checkFields(aFields);
//   const execSingle = (aValidator, aInstance) => {
//     if (!aValidator.isValidatable(fields)) return;
//   };
//   const execArray = (aValidator, aInstance) => {
//     if (!aValidator.isAnyValidatable(fields)) return;
//   };
//   return this.#array.push(Array.isArray(fields) ? execArray : execSingle);
// }
