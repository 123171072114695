import * as Consts from "./MConsts.js";
import autoCode from "./management/autoCoding.js";
import MVConstraints from "./validation/MVConstraints.js";
import { VAcceptedField } from "./validation/MVCore.js";
import { MRtvField, MRtvFields, MRtvItem, MRtv_OBJECT_CONFIGS } from "@systypes";
export { MRtv_OBJECT_CONFIGS };
export * from "./management/autoCoding.js";
const Status_Enabled = "isEnabled";
const Status_Disabled = "isDisabled";
const Status_ReadOnly = "isReadOnly";
const Status_Writable = "isWritable";
const Caption_Default_Name = "Caption";
export class MField extends MRtvField {
	//#region Constructor
	constructor(...args) {
		super(...args);
		this.defineState(Status_Enabled, Status_Disabled);
		this.defineState(Status_Writable, Status_ReadOnly);
	}
	//#region Constructor
	//#region Information
	get fieldKind() {
		return this.config.fieldKind;
	}
	//#endregion Information;
	//#region ValueManagement
	setValue(...args) {
		const result = super.setValue(...args);
		if (result) {
			const { instance } = result;
			const validator = instance?.revalidateByField;
			if (typeof validator === "function") {
				validator.call(instance, this, ...args);
				const config = this.config;
				const changedDependencies = config.changedDependencies;
				if (typeof changedDependencies === "function") {
					const { old_value, new_value } = result;
					const changes = changedDependencies.call(config, new_value, old_value);
					if (changes) {
						changes.forEach((depended) =>
							validator.call(instance, instance[depended.key], depended.new_value, ...args)
						);
					}
				}
			}
		}
		return result;
	}
	//#endregion ValueManagement
}
export class MFields extends MRtvFields {
	static initialize_cls(aFields, aConfig) {
		super.initialize_cls(...arguments);
		const field = new VAcceptedField(aFields);
		aFields.addProperty(Consts.FIELD_NAME_ACCEPTED_WARNINGS, { value: field, enumerable: true });
	}
}
export class MItem extends MRtvItem {
	//#region StatusManagement
	get [Status_Enabled]() {
		return super.getDefinedState(Status_Enabled, false);
	}
	get [Status_Disabled]() {
		return super.getDefinedState(Status_Disabled, true);
	}
	set [Status_Enabled](aValue) {
		return super.setDefinedState(Status_Enabled, aValue, false);
	}
	set [Status_Disabled](aValue) {
		return super.setDefinedState(Status_Disabled, aValue, true);
	}
	get [Status_Writable]() {
		return super.getDefinedState(Status_Writable, false);
	}
	get [Status_ReadOnly]() {
		return super.getDefinedState(Status_ReadOnly, true);
	}
	set [Status_Writable](aValue) {
		return super.setDefinedState(Status_Writable, aValue, false);
	}
	set [Status_ReadOnly](aValue) {
		return super.setDefinedState(Status_ReadOnly, aValue, true);
	}
	//#endregion StatusManagement
}
export class MMeta {
	//#region creators
	constructor(aName, aApp, aEntity) {
		this.#name = aName;
		this.#app = aApp;
		this.#entity = aEntity;
	}
	createWithCopyInfo() {
		return new this.constructor(this.#name, this.#app, this.#entity);
	}
	static create_cls(aEntity, aName, aApp) {
		return new this(aName, aApp, aEntity);
	}
	//#endregion creators
	//#region information
	#name;
	#app;
	#entity;
	#parent;
	get name() {
		return this.#name;
	}
	get app() {
		return this.#app;
	}
	get api() {
		return this.#entity.api;
	}
	get parent() {
		return this.#parent;
	}
	get controller() {
		return this.#entity.controller;
	}
	//#endregion information
	//#region Content
	#myFields;
	#myAliases;
	#myProperties;
	get myFields() {
		return this.#myFields;
	}
	addField(aName, aMember) {
		if (!(aMember instanceof MFieldConfig)) {
			throw Error(`field config ${aName} must be instance of MFieldConfig!..`);
		}
		this.#myFields[aName] = aMember;
	}
	addAlias(aName, aAlias) {
		this.#myAliases[aName] = aAlias;
	}
	addProperty(aName, aProperty) {
		if (!(aProperty instanceof MPropertyConfig)) {
			throw Error(`field config ${aName} must be instance of MPropertyConfig!..`);
		}
		this.#myProperties[aName] = aProperty;
	}
	addLangProperty(aPrefix) {
		const result = new MPropertyLang(aPrefix);
		this.addProperty(aPrefix, result);
		return result;
	}
	addCaption(aName, aFields) {
		const result = new MPropertyCaption(aName, aFields);
		this.addProperty(aName, result);
		return result;
	}
	addTypedField(aName, aDataType, aDeclaration) {
		const result = new MTypedFieldConfig(aDataType, aDeclaration);
		this.addField(aName, result);
		return result;
	}
	//#endregion Content
	//#region Building
	#builded = false;
	get builded() {
		return this.#builded;
	}
	buildingStart() {
		this.#Captions = {};
		this.#myFields = {};
		this.#myAliases = {};
		this.#myProperties = {};
		this.#defaults = {};
		this.constraints = new MVConstraints();
	}
	build(aData, aParent, aBuilder, ...args) {
		if (this.#builded) {
			throw Error(`meta ${this.#name} was already builded!..`);
		}
		this.#builded = true;
		if (aParent) {
			if (aParent === this) {
				throw Error(`ParentMeta for ${this.#name} is same of meta!..`);
			}
			this.#parent = aParent;
		}
		if (aData) {
			this.buildingStart();
			aBuilder(this, aData, ...args);
			this.buildingFinish();
		}
		//deepFreeze(meta);
		return this;
	}
	buildingFinish() {
		if (this.#primaryKey === undefined) {
			const parent = this.#parent?.primaryKey;
			if (parent) {
				this.#primaryKey = parent;
			} else {
				throw Error(`meta ${this.#name} don't have primaryKey!..`);
			}
		}
		if (this.#typedIn === undefined) {
			this.#typedIn = this.#parent?.typedIn;
		}
		if (this.#Captions[Caption_Default_Name] === undefined) {
			const parent = this.parent?.captionFields(Caption_Default_Name);
			if (parent) {
				this.#Captions[Caption_Default_Name] = parent;
			}
		}
		if (Object.keys(this.#myFields).length === 0) this.#myFields = undefined;
		if (Object.keys(this.#myAliases).length === 0) this.#myAliases = undefined;
		if (Object.keys(this.#myProperties).length === 0) this.#myProperties = undefined;
		if (Object.keys(this.#Captions).length === 0) this.#Captions = undefined;
		if (Object.keys(this.#defaults).length === 0) this.#defaults = undefined;
		if (this.constraints.isEmpty) this.constraints = undefined;
	}
	//#endregion Building
	//#region MRtvManagement
	#config;
	createConfig() {
		const result = { meta: this, fieldsClass: MFields, defaultItemClass: MItem };
		const parent = this.#parent?.configs;
		result.fields = parent?.fields ? { ...parent.fields } : {};
		result.aliases = parent?.aliases ? { ...parent.aliases } : {};
		if (this.#myProperties) {
			for (let [name, config] of Object.entries(this.#myProperties)) {
				result.fields[name] = config;
			}
		}
		if (this.#myFields) {
			for (let [name, config] of Object.entries(this.#myFields)) {
				result.fields[name] = config;
			}
		}
		if (this.#myAliases) {
			for (let [name, config] of Object.entries(this.#myAliases)) {
				result.aliases[name] = config;
			}
		}
		if (Object.keys(result.fields).length === 0) delete result.fields;
		if (Object.keys(result.aliases).length === 0) delete result.aliases;
		return result;
	}
	get configs() {
		if (!this.#config) {
			this.#config = this.createConfig();
			if (this.#config.fields) Object.freeze(this.#config.fields);
			if (this.#config.aliases) Object.freeze(this.#config.aliases);
			Object.freeze(this.#config);
		}
		return this.#config;
	}
	//#endregion MRtvManagement
	//#region BasicManagement
	#primaryKey;
	get primaryKey() {
		return this.#primaryKey;
	}
	get primaryField() {
		return this.#primaryKey;
	}
	setPrimaryKey(aValue) {
		if (this.#primaryKey !== undefined) {
			throw Error(`meta ${this.#name} have more than one primaryKey!..`);
		}
		this.#primaryKey = aValue;
	}
	//#endregion BasicManagement
	//#region ClassesManagement
	//#region ManagementClasses
	#classes;
	mngmtClass(aParams, aDeleteUsed) {
		const base = this.#entity.controller;
		if (this.#typedIn) {
			const type = aParams?.[Consts.FIELD_NAME_TYPEID];
			if (type) {
				if (aDeleteUsed) delete aParams[Consts.FIELD_NAME_TYPEID];
				const typeID = +type || type;
				let result = this.#classes?.[typeID]?.deref();
				if (!result) {
					result = this[Consts.CLASS_EXTENDER_TYPED](base, typeID, this.#typedIn);
					if (!this.#classes) this.#classes = { [typeID]: new WeakRef(result) };
					else this.#classes[typeID] = new WeakRef(result);
				}
				return result;
			}
		}
		return base;
	}
	//#endregion ManagementClasses
	//#region typedClasses
	#typedIn;
	get typedIn() {
		return this.#typedIn;
	}
	setTyped(aRelation) {
		if (this.#typedIn !== undefined) {
			throw Error(`meta ${this.#name} have more than one typedRelation!..`);
		}
		if (aRelation?.fieldKind !== "relation") {
			throw Error(`meta ${this.#name} typed expected value relation, but get ${aValue}!..`);
		}
		this.#typedIn = aRelation;
	}
	[Consts.CLASS_EXTENDER_TYPED](aBase, aTypeID, aTypeIn) {
		const meta = this;
		const name = aTypeIn.name;
		const typeID = +aTypeID || aTypeID;
		let type;
		type = (async () => {
			const Class = aTypeIn.verifyInstanceClass ? await aTypeIn.verifyInstanceClass() : aTypeIn.source;
			const value = await Class.loadByPK(typeID, aTypeIn.strategy);
			type = value;
		})();
		class MTyped extends aBase {
			constructor(...args) {
				super(...args);
				const params = { fixed: true, [Consts.FIELD_NAME_TYPE]: true };
				if (type instanceof Promise) {
					type.then(() => this[name].setValue(type, params));
				} else this[name].setValue(type, params);
			}
			static get meta() {
				return meta;
			}

			static async defaultValuesGetter() {
				if (type instanceof Promise) await type;
				const result = (await super.defaultValuesGetter(type)) || {};
				result[name] = type;
				return result;
			}
		}
		Object.defineProperty(MTyped, Consts.FIELD_NAME_TYPEID, { value: typeID, enumerable: false });
		Object.defineProperty(MTyped.prototype, Consts.FIELD_NAME_TYPEID, { value: typeID, enumerable: false });
		const typeGetter = () => type;
		Object.defineProperty(MTyped, Consts.FIELD_NAME_TYPE, { get: typeGetter, enumerable: false });
		Object.defineProperty(MTyped.prototype, Consts.FIELD_NAME_TYPE, { get: typeGetter, enumerable: false });
		return MTyped;
	}
	//#endregion typedClasses
	//#endregion ClassesManagement
	//#region Validation
	validate(aValidator, aInstance) {
		let meta = this;
		const result = [];
		do {
			if (meta.constraints) {
				result.push(...meta.constraints.check(aValidator, aInstance, meta.controller));
			}
			meta = meta.#parent;
		} while (meta);
		return result;
	}
	//#endregion Validation
	//#region Strategies
	get defaultStrategyFields() {
		const fields = [];
		if (this.#primaryKey) {
			if (!Array.isArray(this.#primaryKey)) fields.push(this.#primaryKey);
			else fields.push.call(fields, ...this.#primaryKey);
		}
		const caption = this.#Captions[Caption_Default_Name];
		if (caption) {
			if (!Array.isArray(caption)) fields.push(caption);
			else fields.push.call(fields, ...caption);
		}
		return fields;
	}
	allStrategyFields(aExcludeProperties, aExcludeAliases, aExcludeRelations, aExcludeList) {
		const result = {};
		let meta = this;
		do {
			if (!aExcludeProperties && meta.#myProperties) {
				for (let name of Object.keys(meta.#myProperties)) {
					if (!result[name]) {
						result[name] = true;
					}
				}
			}
			if (meta.#myFields) {
				for (let [name, config] of Object.entries(meta.#myFields)) {
					const kind = config.fieldKind;
					if (kind === "dbTable") {
						const columns = config.columns;
						if (columns) {
							for (let column of config.columns) {
								const name = column.name;
								if (column.foreignKey) continue;
								if (!result[name]) {
									result[name] = true;
								}
							}
						}
						continue;
					}
					if (kind === "relation" && aExcludeRelations) continue;
					if (kind === "list" && aExcludeList) continue;
					if (!result[name]) {
						result[name] = true;
					}
				}
			}
			if (!aExcludeAliases && meta.#myAliases) {
				for (let name of Object.keys(meta.#myAliases)) {
					if (!result[name]) {
						result[name] = true;
					}
				}
			}
			meta = meta.#parent;
		} while (meta);
		return Object.keys(result);
	}
	//#endregion Strategies
	//#region Caption
	#Captions;
	captionFields(aName) {
		return this.#Captions?.[aName];
	}
	addToCaption(aName, aCaptionName) {
		const loc_Add = (caption) => {
			let vls = this.#Captions[caption];
			if (vls) vls.push(aName);
			else {
				vls = [aName];
				this.#Captions[caption] = vls;
				this.addCaption(caption, vls);
			}
		};
		if (aCaptionName === true) loc_Add(Caption_Default_Name);
		else if (!aCaptionName /*ignore*/);
		else if (Array.isArray(aCaptionName)) aCaptionName.forEach((caption) => loc_Add(caption));
		else if (typeof aCaptionName === "string") loc_Add(aCaptionName);
		else {
			throw Error("caption value must be boolean or string or array of string!..");
		}
	}
	get defaultCaption() {
		return this.#Captions?.[Caption_Default_Name] ? Caption_Default_Name : this.primaryKey;
	}
	//#endregion
	//#region New
	#defaults;
	defaultFor(aField, aValue) {
		this.#defaults[aField] = aValue;
	}
	autoCode(aField, aValue) {
		this.#defaults[aField] = autoCode(aValue, aField, this.#defaults[aField]);
	}
	async applyNew(aInstance, aParams) {
		if (!aParams.fieldsValues) {
			aParams.fieldsValues = {};
		}
		const fieldsValues = aParams.fieldsValues;
		const funcs = [];
		const loc_apply = (meta) => {
			if (meta.#parent) loc_apply(meta.#parent);
			const defaults = meta.#defaults;
			if (!defaults) return;
			for (let [field, value] of Object.entries(defaults)) {
				if (typeof value !== "function") {
					if (!(field in fieldsValues)) {
						fieldsValues[field] = value;
					}
				} else {
					const handler = new Proxy(
						{},
						{
							get(aTarget, aProperty, aReceiver) {
								if (aProperty === "constructor") {
									return meta.#entity.controller;
								}
								if (aProperty in fieldsValues) {
									return fieldsValues[aProperty];
								}
								return aInstance[aProperty];
							},
						}
					);
					const fnRes = value.call(handler, aParams);
					if (fnRes && fnRes instanceof Promise) {
						fnRes.then((value) => (fieldsValues[field] = value));
						funcs.push(fnRes);
					} else fieldsValues[field] = fnRes;
				}
			}
		};
		loc_apply(this);
		await Promise.all(funcs);
		return fieldsValues;
	}
	//#endregion New;
}

export class MFieldConfig {
	//#region Information
	get fieldClass() {
		return MField;
	}
	get fieldKind() {
		return "normal";
	}
	//#region Information
}

export class MPropertyConfig {
	//#region Constructor
	constructor(aGetter, aSetter) {
		const descriptor = { configurable: false, enumerable: false };
		if (aGetter) descriptor.get = aGetter;
		if (aSetter) descriptor.set = aSetter;
		this.specificValue = descriptor;
	}
	//#endregion Constructor
	//#region information
	get fieldKind() {
		return "property";
	}
	//#endregion information
}

export class MPropertyLang extends MPropertyConfig {
	//#region Constructor
	constructor(aPrefix) {
		//Zafer Language
		const getter = function () {
			return this.valueOf(`${aPrefix}_AR`);
		};
		const setter = function (aValue) {
			return this.setValueOf(`${aPrefix}_AR`, aValue, { byPropertySetter: true });
		};
		super(getter, setter);
		this.prefix = aPrefix;
	}
	//#endregion Constructor
}

export class MPropertyCaption extends MPropertyConfig {
	//#region Constructor
	constructor(aName, aFields) {
		const getter = function (...args) {
			let result = "";
			for (let name of aFields) {
				const text = this.valueOf(name, ...args);
				if (text) {
					result = result ? `${result} - ${text}` : text;
				}
			}
			return result;
		};
		super(getter, undefined);
		this.name = aName;
		this.usesFields = aFields;
	}
	//#endregion Constructor
}

export class MTypedFieldConfig extends MFieldConfig {
	//#region Constructor
	constructor(aType) {
		super();
		this.datatype = aType;
	}
	//#endregion Constructor
	//#region information
	get fieldKind() {
		return this.datatype.typeName;
	}
	//#endregion information
}

export default MMeta;
