export default function ListField(aBaseField) {
	class ListField extends aBaseField {
		//#region Validation
		validate(aValidator) {
			if (aValidator.fields) return;
			const value = super.value;
			if (!value) return;
			value.validate(aValidator, this.name, this.fieldTitle);
		}
		//#endregion Validation
	}
	return ListField;
}
