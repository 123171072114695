import { MField, MFieldConfig } from "../MMeta.js";

class MSetElementField extends MField {
	//#region Constructor
	constructor(...args) {
		super(...args);
		const config = this.config;
		const set = config.setConfig;
		const bit = config.element.value;
		const setField = this.fields.fields[config.setName];
		Object.defineProperty(this, "value", {
			get: () => {
				const value = set.valueOrEmpty(setField.value);
				return value ? (value & bit) === bit : false;
			},
		});
		this.setValue = (aValue, aParams) => {
			return setField.setValue(set.resetValue(setField.value, bit, aValue), aParams);
		};
	}
	//#endregion Constructor
}
class MSetElementConfig extends MFieldConfig {
	//#region Constructor
	constructor(aSet, aFieldName, aElement) {
		super();
		this.setConfig = aSet;
		this.setName = aFieldName;
		this.element = aElement;
	}
	//#endregion Constructor
	//#region ApiManagment
	addToQuery(aQuery, aPath) {
		const path = aPath ? `${aPath}.${this.setName}` : this.setName;
		aQuery.addNeededColumn(path);
	}
	//#endregion ApiManagment
	//#region Information
	get title() {
		return this.element.title;
	}
	get innumerable() {
		return true;
	}
	get fieldClass() {
		return MSetElementField;
	}
	get fieldKind() {
		return "SetElement";
	}
	//#region Information
}

export function initSetConfig(aConfig, aSet, aMeta, aPerfix, aName, aDeclaration) {
	const onChanged = aDeclaration?.onChanged;
	if (typeof onChanged === "function") {
		aSet.onChanged = onChanged;
	}
	if (aPerfix) {
		//add alias, by apiTable;
		aSet.forEachElement((element) => aMeta.addAlias(element.fieldName, `${aPerfix}.${element.fieldName}`));
	} else {
		const loc_defineField = (element) => aMeta.addField(element.fieldName, new MSetElementConfig(aSet, aName, element));
		aSet.forEachElement(loc_defineField);
	}
	aConfig.changedDependencies = (aNew, aOld) => {
		const result = [];
		const newSet = aSet.valueOrEmpty(aNew);
		const oldSet = aSet.valueOrEmpty(aOld);
		aSet.forEachElement((element) => {
			const bit = element.value;
			const new_value = (newSet & bit) === bit;
			const old_value = (oldSet & bit) === bit;
			if (new_value !== old_value) {
				result.push({ key: element.fieldName, /*config,*/ new_value, old_value });
			}
		});
		return result.length > 0 ? result : undefined;
	};
}

export const getSetElementConfig = (aSet, aFieldName, aElement) => new MSetElementConfig(aSet, aFieldName, aElement);
